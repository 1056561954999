<template>
    <v-dialog
        persistent
        @click:outside="falseButtonClicked()"
        @keydown.esc="falseButtonClicked()"
        v-model="internalShowDialog"
        min-width="900"
        max-width="900"
    >
        <v-card>
            <v-card-title class="text-title">
                {{ $t("vehicles.driver_name") }}
            </v-card-title>

            <v-card-text class="dialog-text text-content" color="black" v-if="isContentLoaded">
				<v-row class="mt-3">
					<v-col>
						<v-row>
							<v-col class="pa-0 ma-0" cols="12" sm="7"> </v-col>
							<v-col class="pa-0 ma-0" cols="12" sm="3">
								<v-text-field
								class="mr-3 to-upper"
								:label="$t('search')"
								prepend-icon="mdi-magnify"
								v-bind:clearable="true"
								clear-icon="mdi-close"
								v-model="search"
								:outlined="is_boxes"
								:dense="is_boxes"
								></v-text-field>
							</v-col>
							<v-col class="pa-0 ma-0" cols="12" sm="1">
								<v-btn
									class="mt-3"
									color="primary"
									@click="openEditClientMemberModel = true"
								>
									{{ $t("nav_buttons.add") }}
									<v-icon dark right> mdi-plus </v-icon>
								</v-btn>
							</v-col>
						</v-row>

						<v-row>
							<v-data-table
								fixed-header
								:footer-props="{
								'items-per-page-options': dataTableOptions,
								}"
								:headers="headers"
								:items="clients"
								:options="options"
								:server-items-length="totalItems"
								:loading="isLoading"
								:loading-text="this.$t('loading')"
								class="elevation-0"
								:items-per-page="itemsPerPage"
								item-key="identifier"
								@update:page="updatePagination"
								@update:items-per-page="updateItemsPerPage"
								@click:row="editClientMember(item)"
								:height="'30vh'"
								style="width: 100%"
								:sort-by.sync="sortBy"
								:sort-desc.sync="sortDesc"
								@update:sort-by="updateSort"
								@update:sort-desc="updateSortOrder"
								mustSort
								:key="forceReloadComponentKey"
							>
								<template v-slot:item="{ item }">
								<tr>
									<!-- <td @click="editClientMember(item)">{{ item.id }}</td> -->
									<td @click="editClientMember(item)">
										<template v-if="item.type">
											{{ $t("member_types." + item.type.key) }}
										</template>
									</td>
									<td @click="editClientMember(item)">
									{{ item.name + (item.first_name ? " " + item.first_name : "") }}
									</td>
									<!-- <td @click="editClientMember(item)">
									<span v-if="item.company">
										{{ $t("clients.yes") }}
									</span>
									<span v-else>
										{{ $t("clients.no") }}
									</span>
									</td> -->
									<td @click="editClientMember(item)">
										<span>{{ formatToPhoneNumber(item.phone) }}</span>
									</td>
									<td @click="editClientMember(item)">
										<span>{{ formatToPhoneNumber(item.gsm) }}</span>
									</td>
									<td @click="editClientMember(item)">
										<span>{{ item.email }}</span>
									</td>
									<!-- <td @click="editClientMember(item)">{{ item.currency.key }}</td> -->

									<!-- <td @click="editClientMember(item)">{{ item.familyClient.name }}</td> -->
									
									<!-- <td @click="editClientMember(item)">
									<v-icon v-if="item.note" color="warning" class="mr-1">
										mdi-information
									</v-icon>
									</td> -->
									<!-- <td>
										<v-icon class="pt-2 ml-n2" @click="editClientMember(item)">mdi-pencil</v-icon>
									</td> -->
								</tr>
								</template>
							</v-data-table>
						</v-row>
						<edit-client-model
							v-if="openEditClientMemberModel"
							@buttonClicked="updatedMember"
							:defaultClient="selectedClientMember"
							:addMemberToCompany="client"
							:showDialog.sync="openEditClientMemberModel"
							:addMember="true"
						></edit-client-model>
					</v-col>
				</v-row>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions class="pr-2">
					<v-spacer></v-spacer>
					<v-btn color="grey lighten-2" @click="falseButtonClicked()">
						{{ $t("attachment.close") }}
						<v-icon dark right> mdi-close </v-icon>
					</v-btn>
				</v-card-actions>
			</v-card>
    </v-dialog>
</template>

<script>
import _ from "lodash";
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import { validationMixin } from "vuelidate";
import { mapState } from "vuex";
import moment from "moment";
import { DateTime } from "luxon";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import EditClientModel from "@/components/EditClientModel.vue";

export default {
    name: "MemberSelect",
    components: {
		"edit-client-model": EditClientModel,
	},
    mixins: [],
    props: {
		client: {type: Object},
        showDialog: { type: Boolean, default: true}
    },
    computed: {
		headers() {
			return [
			// { text: "ID", value: "id" },
			{ text: this.$t("vehicles.clients_model.member_type"), value: "type" },
			{
				text: this.$t("clients.name"),
				align: "start",
				sortable: true,
				value: "name",
			},
			// {
			//     text: this.$t("clients.company"),
			//     value: "representative_company_id",
			// },
			// { text:  this.$t('clientOtherName'), align: 'start', value: 'other_name' },
			{ text: this.$t("clients.phone"), value: "phone" },
			{ text: this.$t("clients.gsm"), value: "gsm" },
			// {text: this.$t('clientFax'), value: 'fax'},
			{ text: this.$t("clients.email"), value: "email" },
			// {text: this.$t('clients.web'), value: 'web'},
			// {
			//     text: this.$t("clients.currency"),
			//     value: "currency.key",
			//     sortable: false,
			// },
			// { text: this.$t('clients.user'), value: 'user.name' , sortable:false},
			// { text: this.$t('clients.status'), value: 'status.key', sortable:false },
			// {
			//     text: this.$t("clients.family"),
			//     value: "family_client",
			//     sortable: false,
			// },
			// { text: this.$t("clients.note"), value: "note" },
			// {text: this.$t('createdAt'), value: 'created_at'},
			// {text: this.$t('updatedAt'), value: 'updated_at'},
			// { text: this.$t('actions'), value: 'actions', sortable: false, align: 'start' }
			];
		},
	},
    data() {
        return {
            internalShowDialog: this.showDialog,
			autocompleteValueLocal: "off",
			refreshContentOnChange: true,
			isContentLoaded: false,
			memberTypes: [],

			openEditClientMemberModel: false,
			is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
			isLoading: false,
			totalItems: 0,
			lastPage: 1,
			sortBy: "name",
			sortDesc: true,
			sortOrd: "ASC",
			itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
			clients: [],
			search: "",
			options: {},
			dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
			// header
			token: this.$store.state.AccessToken,
			header: { Authorization: "Bearer " + this.$store.state.AccessToken },
			selectedClientMember: null,
        };
    },
    mounted() {
		this.getMemberTypes();
		this.$nextTick(() => {
			this.isContentLoaded = true;
		});
		this.fetchData();
	},
    methods: {
		updatedMember(){
			this.fetchData();
			this.openEditClientMemberModel = false;
			this.selectedClientMember = null;
		},
		editClientMember(item) {
			this.selectedClientMember = item;
			this.openEditClientMemberModel = true;
		},
		addClientMember(item) {
			this.selectedClientMember = null;
			this.openEditClientMemberModel = true;
		},
		fetchData(currentPage = null) {
			this.isLoading = true;
			axios
			.get(
				API_BASE_URL +
				"/clients?searchRegNumber=true&membersOnly=true&page=" +
				this.page +
				"&items_per_page=" +
				this.itemsPerPage +
				(this.client ? ("&representative_company_id=" + this.client.representative_company_id) : '')+
				(this.search
					? "&search=" + encodeURIComponent(this.search)
					: "" + "&sortby=" + this.sortBy + "&sorttype=" + this.sortOrd),
				{
				headers: this.header,
				}
			)
			.then(({ data }) => {
				this.clients = data.data;
				this.lastPage = data.meta.last_page;
				this.totalItems = data.meta.total;
				this.page = data.meta.current_page;
				// this.itemsPerPage = parseInt(data.meta.per_page);
				// this.sortBy = null;
				// this.sortDesc = null;
				this.isLoading = false;
			})
			.catch((err) => {
				console.log(err);
				this.isLoading = false;
			});
		},
		updatePagination(page) {
			this.page = page;
			this.fetchData(page);
		},
		updateItemsPerPage(itemsPerPage) {
			this.itemsPerPage = itemsPerPage == -1 ? this.totalItems : itemsPerPage;
			this.fetchData();
			this.itemsPerPage = this.itemsPerPage == -1 ? -1 : itemsPerPage;
		},
		editItem(item) {
			this.$store.commit("seteditId", item.id);
			this.$router.push("/client/edit").catch(() => {});
		},
		deleteItem(item) {
            // 
        },

		updateSort(sortBy) {
			if (typeof sortBy == "object") {
			this.sortBy = sortBy[0];
			} else {
			this.sortBy = sortBy;
			}
			this.fetchData();
		},
		updateSortOrder(sortDesc) {
			if (typeof sortDesc == "object") {
			!sortDesc[0] ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
			} else {
			!sortDesc ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
			}

			this.fetchData();
		},
		onSearch() {
			this.fetchData();
		},
        saveItem(item) {

        },
        falseButtonClicked(item) {
            this.$emit("update:showDialog", false);
            this.$emit("buttonClicked", false);
            this.internalShowDialog = false;
        },
		async getMemberTypes(){
			await this.getCodes('member_types', 'member_types')
			.then((data) => {
				this.memberTypes = data.filter((element) => {
					return element.key != 'not_specified'
				})
			})
		},
    },
    watch: {
        showDialog: {
            handler: function(val) {
                this.internalShowDialog = val;
            },
        }
    },
	beforeDestroy(){
		this.$emit("update:showDialog", false);
	}
};
</script>

<style scoped>

.right-aligned-input >>> input {
	text-align: right !important;
}
</style>