<template>
    <v-dialog
        v-model="popUpModelVisible"
        max-width="1000"
        @click:outside="closePopUpModel()"
        @keydown.esc="closePopUpModel()"
    >
        <v-card>
            <v-card-title>
                <span class="text-h5">
                    {{ $t("receptions.reception_details") }} -
                    {{
                        data.article_original_name.original_name
                    }}</span
                >
            </v-card-title>
            <v-card-text>
                <v-row>
                    <span> </span>
                    <v-col cols="12" sm="3">
                        <v-autocomplete
                            flat
                            :items="allSuppliers"
                            v-model="data.saved_supplier_order_detail.supplier_order.supplier"
                            :label="$t('receptions.supplier')"
                            class="mr-2 to-upper"
                            item-value="id"
                            item-text="name"
                            return-object
                            :outlined="is_boxes"
                            :dense="is_boxes"
                            hide-no-data
                            @change="setReceptionStatusToStatus('successfully_ordered')"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-text-field
                            v-model="
                                data.saved_supplier_order_detail.ordered_quantity
                            "
                            :label="$t('receptions.ordered_quantity')"
                            class="mr-2 to-upper"
                            :outlined="is_boxes"
                            :dense="is_boxes"
                            @input="setReceptionStatusToStatus('successfully_ordered')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-text-field
                            v-model="
                                data.saved_supplier_order_detail.confirmed_quantity
                            "
                            :label="$t('receptions.confirmed_quant_label')"
                            @input="setReceptionStatusToStatus('successfully_ordered')"
                            class="mr-2 to-upper"
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-text-field
                            v-model="
                                data.saved_supplier_order_detail.received_quantity
                            "
                            :label="$t('receptions.received_quant_label')"
                            @input="setReceptionStatusDynamically()"
                            class="mr-2 to-upper"
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-autocomplete
                            v-model="data.saved_supplier_order_detail.supplier_order.status"
                            :items="rec_statuses"
                            :label="$t('receptions.status')"
                            item-value="id"
                            item-text="text"
                            class="mr-2"
                            return-object
                            :outlined="is_boxes"
                            :dense="is_boxes"
                            @click:append="status = null"
                            hide-details
                            append-icon="mdi-close"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey-lighten-2" @click="closePopUpModel()">
                    {{ $t("receptions.modify_article.cancel") }}
                    <v-icon dark right> mdi-keyboard-return </v-icon>
                </v-btn>

                <v-btn
                    color="green"
                    dark
                    @click="updateReceptionOrder()"
                    v-if="this.data.saved_supplier_order_detail.supplier_order.status_id != 10091005 "
                >
                    {{ $t("receptions.modify_article.save") }}
                    <v-icon right> mdi-content-save </v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { validationMixin } from "vuelidate";
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";

export default {
    mixins: [validationMixin],
    validations: {},
    props: {
        data: [Object],
    },
    data() {
        return {
            is_boxes: this.getCompanyPropertyFromLocalStorage(
                "boxes_on_fields",
                1
            ),
            popUpModelVisible: true,
            autocompleteValueLocal: "off",
            rec_statuses: [],
            localData: null
        };
    },
    async mounted() {
        this.autocompleteValueLocal = this.autocompleteValue();
        this.localData = { ...this.data };
        await this.getStatuses();
    },
    methods: {
        setReceptionStatusDynamically(){
            if(this.data.saved_supplier_order_detail.ordered_quantity <= this.data.saved_supplier_order_detail.received_quantity){
                this.setReceptionStatusToStatus('fully_received');
            }
            else{
                this.setReceptionStatusToStatus('successfully_ordered')
            }
        },
        setReceptionStatusToStatus(newStatus) {
            this.rec_statuses.forEach((element) => {
                if (element.key == newStatus) {
                    this.data.saved_supplier_order_detail.supplier_order.status = element;
                }
            });
        },
        closePopUpModel() {
            this.popUpModelVisible = false;
        },
        getStatuses() {
            return new Promise((resolve, reject) => {
                this.getCodes(
                    "supplier_order_statuses",
                    "receptions.statuses"
                ).then((data) => {
                    this.rec_statuses = data;
                    resolve();
                });
            });
        },
        async updateReceptionOrder(){
            await this.saveReception(this.makeSubmitData()).then(() => {

            });
        },
        makeSubmitData() {
            let data = {
                is_partial_update: 1,
                supplier_id: this.data.saved_supplier_order_detail.supplier_order.supplier.id,
                status_id: this.data.saved_supplier_order_detail.supplier_order.status && typeof this.data.saved_supplier_order_detail.supplier_order.status == 'object' ? this.data.saved_supplier_order_detail.supplier_order.status.id : null,
                ordered_quantity: this.data.saved_supplier_order_detail.ordered_quantity,
                confirmed_quantity: this.data.saved_supplier_order_detail.confirmed_quantity,
                article_id: this.data.saved_supplier_order_detail.article_id,
            };
            return data;
        },
        saveReception(data) {
            return new Promise((resolve) => {
                if(this.data.saved_supplier_order_detail.supplier_order_id){
                    axios
                    .put(
                        API_BASE_URL + "/suppliers/order/" + this.data.saved_supplier_order_detail.supplier_order_id,
                        data,
                        { headers: this.header }
                    )
                    .then((data) => {
                        this.$toast.success(
                            this.$t(
                                "receptions.text_messages.reception_updated_successfully"
                            )
                        );
                        // When fully received we will create reception and inject it
                        if(this.data.saved_supplier_order_detail.received_quantity > 0 && this.data.saved_supplier_order_detail.supplier_order.status && typeof this.data.saved_supplier_order_detail.supplier_order.status == 'object' && this.data.saved_supplier_order_detail.supplier_order.status && this.data.saved_supplier_order_detail.supplier_order.status.key == 'fully_received'){
                            this.createReception().then(() => {
                                this.closePopUpModel();
                            });
                        }
                        else{
                            this.closePopUpModel();
                        }
                    })
                    .finally(() => {
                        resolve();
                    });
                }
                else{
                    resolve();
                }
            });
        },
        makeSupplierReceptionRequest() {
            let reception_items = [];
            // push only those, whose received quantity is greater then 0 else, backend code will process them also
            if (this.data.saved_supplier_order_detail.received_quantity > 0) {
                let reception_data = {
                    srno: 1,
                    article_id: this.data.saved_supplier_order_detail.article_id,
                    localisation: "",
                    totalOrdered: this.data.saved_supplier_order_detail
                        .ordered_quantity,
                    // price: item.price,
                    received: parseInt(
                        this.data.saved_supplier_order_detail.received_quantity
                    ), // + parseInt(this.already_received_quantity),
                    // old_reception:
                    //     this.data.supplier_order_reception.reception_number ??
                    //     null,
                    dot_quantities: null,
                    is_verified: true,
                    price: this.data.saved_supplier_order_detail.acquisition_price,
                };
                if (this.data.saved_supplier_order_detail.supplier_order_id) {
                    // add supplier_order_id for modifying just that supplier order detail
                    reception_data["supplier_order_id"] = this.data.saved_supplier_order_detail.supplier_order_id;
                }
                reception_items.push(reception_data);
            }
            let request = {
                delivery_note_ref: this.data && this.data.saved_supplier_order_detail && this.data.saved_supplier_order_detail.supplier_order && this.data.saved_supplier_order_detail.supplier_order.reference ? this.data.saved_supplier_order_detail.supplier_order.reference : "FROM_ORDER_" + this.generateRandomString(10),
                rec_items: reception_items,
                supplier: this.data && this.data.saved_supplier_order_detail && this.data.saved_supplier_order_detail.supplier_order_id && this.data.saved_supplier_order_detail.supplier_order ? this.data.saved_supplier_order_detail.supplier_order.supplier_id : null,
                force_in_successfully: true,
                update_other_details_also: true
            };
            return request;
        },
        createReception() {
            return new Promise((resolve, reject) => {
                axios
                .post(
                    API_BASE_URL + "/receptions/create",
                    this.makeSupplierReceptionRequest(),
                    { headers: this.header }
                )
                .then(async (response) => {
                    if (response.status === 200) {
                        this.$toast.success(
                            this.$t(
                                "receptions.text_messages.reception_created_successfully"
                            )
                        );
                        console.log("reception created", response.data);
                        if(response.data.id){
                            await this.injectReception(response.data.id, response.data);
                        }
                    } else {
                        this.$toast.error(
                            this.$t(
                                "receptions.text_messages.failed_while_sending"
                            )
                        );
                    }
                })
                .catch((error) => {
                    this.$toast.error(
                        this.$t("receptions.text_messages.failed_while_sending")
                    );
                })
                .finally(() => {
                    resolve()
                });
            });
        },
        injectReception(id, requestDataForInject) {
            return new Promise((resolve, reject) => {
                let data = {
                    delivery_note_reference: requestDataForInject.delivery_note_reference,
                    supplier_id: requestDataForInject.supplier_id,
                    rec_items: requestDataForInject.scan_data
                }
                
                axios
                    .post(
                        API_BASE_URL +
                            "/receptions/scans/" + id + "/inject",
                            data,
                        {
                            headers: this.header,
                        }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            this.$toast.success(
                                this.$t(
                                    "receptions.text_messages.injected_successfully"
                                )
                            );
                        } else {
                            this.$toast.error(
                                this.$t(
                                    "receptions.text_messages.failed_while_injecting"
                                )
                            );
                        }
                    })
                    .catch((error) => {
                        this.$toast.error(
                            this.$t(
                                "receptions.text_messages.failed_while_injecting"
                            )
                        );
                    })
                    .finally(() => {
                        resolve();
                    });
            });
        },
    },
    computed: {
        allSuppliers() {
            return this.$store.state.allSuppliers ?? [];
        },
    },
    watch: {
        popUpModelVisible(val) {
            this.$emit("change-popup-model-visibility", true);
        },
        data: {
            handler: function(val) {
                this.rec_status = this.data.status;
            },
            immediate: true,
        },
    },
};
</script>
