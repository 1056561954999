var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-tabs',{attrs:{"background-color":"primary","dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{key:"reserves"},[_vm._v(" "+_vm._s(_vm.$t("stocks.label_reserve"))+" ")]),_c('v-tab',{key:"supplier"},[_vm._v(" "+_vm._s(_vm.$t("scheduler.supplier"))+" "+_vm._s(_vm.$t("vehicles.vehicle_tab.orders"))+" ")]),_c('v-tab',{key:"reception_history"},[_vm._v(" "+_vm._s(_vm.$t("stocks.reception_history"))+" ")]),_c('v-tab',{key:"latest_sales_of_article"},[_vm._v(" "+_vm._s(_vm.$t("stocks.latest_sales_of_article"))+" ")]),_c('v-tab',{key:"dots"},[_vm._v(" "+_vm._s(_vm.$t("stocks.dots"))+" "),(_vm.dots.length > 0 && _vm.pendingReceivedQuantity() !== 0)?_c('v-icon',{staticClass:"ml-2",attrs:{"color":"red"}},[_vm._v("mdi-alert")]):_vm._e()],1)],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:'reserves'},[_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.reservedStocksHeaders,"items":_vm.reservedStockTableItems,"items-per-page":_vm.reservedStockTableItems.length,"footer-props":{
                            'items-per-page-options': _vm.dataTableForReserveOptions,
                        },"options":_vm.forReserveOptions,"sort-by":_vm.sortByForReserve,"sort-desc":_vm.sortDescForReserve,"height":"450px"},on:{"update:sortBy":function($event){_vm.sortByForReserve=$event},"update:sort-by":[function($event){_vm.sortByForReserve=$event},_vm.updateSortForReserve],"update:sortDesc":function($event){_vm.sortDescForReserve=$event},"update:sort-desc":[function($event){_vm.sortDescForReserve=$event},_vm.updateSortOrderForReserve],"update:page":_vm.updatePaginationForReserve,"update:items-per-page":_vm.updateItemsPerPageForReserve},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                        var item = ref.item;
return [_c('tr',{class:_vm.setRowColorClassForReserved(item)},[_c('td',[_vm._v(" "+_vm._s(item.full_name)+" ")]),_c('td',[(item.appointment_date)?_c('span',{style:({
                                            color: 'blue',
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                        }),on:{"click":function($event){return _vm.navigateToAppointmentPage(
                                                item.appointment_id
                                            )}}},[_vm._v(" "+_vm._s(item.appointment_date)+" ")]):_vm._e()]),_c('td',[_vm._v(" "+_vm._s(item.quantity)+" "),(item.received_quantity > 0)?[_c('span',{staticClass:"text-green"},[_vm._v(" ("+_vm._s(item.received_quantity)+") ")])]:_vm._e()],2),_c('td',[_vm._v(" "+_vm._s((item.supplier_order_acquisition_price ? item.supplier_order_acquisition_price : 0 ).toFixed(_vm.getDecimalNumber()))+" ")]),_c('td',[_vm._v(" "+_vm._s((item.order_selling_price ? item.order_selling_price : 0 ).toFixed(_vm.getDecimalNumber()))+" ")]),_c('td',[_c('span',{style:({
                                            color: 'blue',
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                        }),on:{"click":function($event){return _vm.navigateToOrdersPage(item.order_id)}}},[_vm._v(" "+_vm._s(item.identifier)+" ")])]),_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(item.order_created_at))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.orderer_name)+" ")])])]}}])})],1)],1),_c('v-tab-item',{key:'supplier'},[_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.reservedSupplierStockHeaders,"items":_vm.reservedSupplierStockTableItems,"items-per-page":_vm.reservedSupplierStockTableItems.length,"sortable":false,"footer-props":{
                            'items-per-page-options': _vm.dataTableForSupplierStockOptions,
                        },"options":_vm.forSupplierStockOptions,"sort-by":_vm.sortByForSupplierStock,"sort-desc":_vm.sortDescForSupplierStock,"height":"450px","item-class":_vm.itemRowBackground},on:{"update:sortBy":function($event){_vm.sortByForSupplierStock=$event},"update:sort-by":[function($event){_vm.sortByForSupplierStock=$event},_vm.updateSortForSupplierStock],"update:sortDesc":function($event){_vm.sortDescForSupplierStock=$event},"update:sort-desc":[function($event){_vm.sortDescForSupplierStock=$event},_vm.updateSortOrderForSupplierStock],"update:page":_vm.updatePaginationForSupplierStock,"update:items-per-page":_vm.updateItemsPerPageForSupplierStock,"click:row":_vm.editSupplierStockTableItem},scopedSlots:_vm._u([{key:"item.icons",fn:function(ref){
                        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","z-index":"9999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [(item.status_id == 10091000)?_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-file-document-multiple ")]):(item.status_id == 10091001)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-cart-check ")]):(item.status_id == 10091002)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v(" mdi-close-network ")]):(item.status_id == 10091003)?_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-archive-cancel ")]):(item.status_id == 10091004)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success"}},'v-icon',attrs,false),on),[_vm._v(" mdi-archive-arrow-down")]):(item.status_id == 10091005)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success"}},'v-icon',attrs,false),on),[_vm._v(" mdi-archive-check ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.translateKeys( _vm.getReceptionStatusById( item.status_id ), "key", "receptions.statuses" ))+" ")])])]}},{key:"item.supplier",fn:function(ref){
                        var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.supplier.supplier_identifier)+" ")])]}},{key:"item.quantity",fn:function(ref){
                        var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.quantity)+" ")]),_vm._v(" / "),_c('span',[_vm._v(" "+_vm._s(item.confirmed_quantity)+" "),(item.received_quantity > 0)?[_c('span',{staticClass:"text-green"},[_vm._v(" ("+_vm._s(item.received_quantity)+") ")])]:_vm._e()],2)]}},{key:"item.document_number",fn:function(ref){
                        var item = ref.item;
return [_c('span',{style:({
                                    color: 'blue',
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                }),on:{"click":function($event){return _vm.navigateToOrdersPage(item.order_id)}}},[_vm._v(" "+_vm._s(item.identifier)+" ")])]}},{key:"item.short_name",fn:function(ref){
                                var item = ref.item;
return [(item.short_name)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(item.short_name))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.orderer_name))])])]:_vm._e()]}},{key:"item.appointment_date",fn:function(ref){
                                var item = ref.item;
return [(item.appointment_date)?_c('span',{style:({
                                    color: 'blue',
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                }),on:{"click":function($event){return _vm.navigateToAppointmentPage(
                                        item.appointment_id
                                    )}}},[_vm._v(" "+_vm._s(item.appointment_date ? _vm.formatDate(item.appointment_date) : "")+" ")]):_vm._e()]}},{key:"item.ordered_on",fn:function(ref){
                                    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.ordered_on ? _vm.formatDate(item.ordered_on) : "")+" ")])]}},{key:"item.delivery_date",fn:function(ref){
                                    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.delivery_date ? _vm.formatDate(item.delivery_date) : "")+" ")])]}},{key:"item.orderer_name",fn:function(ref){
                                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.orderer_name)+" ")]}}])})],1)],1),_c('v-tab-item',{key:'reception_history'},[_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.receptionHistoryHeaders,"items":_vm.articleReceptionHistory,"server-items-length":_vm.totalItemsForReceptionHistory,"items-per-page":_vm.articleReceptionHistory.length,"sortable":false,"footer-props":{
                            'items-per-page-options': _vm.dataTableForReceptionHistoryOptions,
                        },"options":_vm.forReceptionHistoryOptions,"sort-by":_vm.sortByForReceptionHistory,"sort-desc":_vm.sortDescForReceptionHistory,"height":"450px"},on:{"update:sortBy":function($event){_vm.sortByForReceptionHistory=$event},"update:sort-by":[function($event){_vm.sortByForReceptionHistory=$event},_vm.updateSortForReceptionHistory],"update:sortDesc":function($event){_vm.sortDescForReceptionHistory=$event},"update:sort-desc":[function($event){_vm.sortDescForReceptionHistory=$event},_vm.updateSortOrderForReceptionHistory],"update:page":_vm.updatePaginationForReceptionHistory,"update:items-per-page":_vm.updateItemsPerPageForReceptionHistory},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                        var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(item.supplier_identifier ? item.supplier_identifier : "INT")+" ")]),_c('td',[_vm._v(" "+_vm._s(item.delivery_note_reference)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.reception_received_quantity)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.acquisition_price.toFixed( _vm.getDecimalNumber() ))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.delivery_date ? _vm.formatDate(item.delivery_date) : "")+" ")]),_c('td',[_vm._v(" "+_vm._s(item.reception_created_at ? _vm.formatDateTime( item.reception_created_at ) : "")+" ")])])]}}])})],1)],1),_c('v-tab-item',{key:'latest_sales_of_article'},[_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.latestArticleHeaders,"items":_vm.latestArticles,"server-items-length":_vm.totalItemsForLatestArticle,"footer-props":{
                            'items-per-page-options': _vm.dataTableForLatestArticleOptions,
                        },"options":_vm.forLatestArticleOptions,"items-per-page":_vm.latestArticles && _vm.latestArticles.length,"sort-by":_vm.sortByForLatestArticle,"sort-desc":_vm.sortDescForLatestArticle,"height":"450px"},on:{"update:sortBy":function($event){_vm.sortByForLatestArticle=$event},"update:sort-by":[function($event){_vm.sortByForLatestArticle=$event},_vm.updateSortForLatestArticle],"update:sortDesc":function($event){_vm.sortDescForLatestArticle=$event},"update:sort-desc":[function($event){_vm.sortDescForLatestArticle=$event},_vm.updateSortOrderForLatestArticle],"update:page":_vm.updatePaginationForLatestArticle,"update:items-per-page":_vm.updateItemsPerPageForLatestArticle},scopedSlots:_vm._u([{key:"item.acquisition_price",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s((item.acquisition_price ? item.acquisition_price : 0 ).toFixed(_vm.getDecimalNumber()))+" ")]}},{key:"item.full_name",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(item.name ? item.name : "")+" ")]}},{key:"item.orderer_name",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(item.orderer_name)+" ")]}},{key:"item.client_price",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s((item.price ? item.price : 0).toFixed( _vm.getDecimalNumber() ))+" ")]}},{key:"item.document_number",fn:function(ref){
                        var item = ref.item;
return [_c('span',{style:({
                                    color: 'blue',
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                }),on:{"click":function($event){return _vm.navigateToOrdersPage(item.id)}}},[_vm._v(" "+_vm._s(item.identifier)+" ")])]}},{key:"item.created_at",fn:function(ref){
                                var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.created_at ? _vm.formatDateTime(item.created_at) : "")+" ")])]}},{key:"item.quantity",fn:function(ref){
                                var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.quantity))+" ")]}}])})],1)],1),_c('v-tab-item',{key:'dots'},[_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loadingDot,"headers":_vm.dotHeaders,"items":_vm.dots,"server-items-length":_vm.totalItemsForDots,"footer-props":{
                            'items-per-page-options': _vm.dataTableForDotOptions,
                        },"options":_vm.forDotOptions,"items-per-page":_vm.dots && _vm.dots.length,"sort-by":_vm.sortByForDot,"sort-desc":_vm.sortDescForDot,"height":"390px"},on:{"update:sortBy":function($event){_vm.sortByForDot=$event},"update:sort-by":[function($event){_vm.sortByForDot=$event},_vm.updateSortForDot],"update:sortDesc":function($event){_vm.sortDescForDot=$event},"update:sort-desc":[function($event){_vm.sortDescForDot=$event},_vm.updateSortOrderForDot],"update:page":_vm.updatePaginationForDot,"update:items-per-page":_vm.updateItemsPerPageForDot},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"pt-8",attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{staticClass:"mr-3 pb-5 to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t(
                                                    'receptions.modify_article.dot'
                                                ),"clearable":true,"clear-icon":"mdi-close","disabled":_vm.pendingReceivedQuantity() <= 0,"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.addDotToDataTable()},function($event){return _vm.handleInput($event, 'int', 4)}]},model:{value:(_vm.dot_property),callback:function ($$v) {_vm.dot_property=$$v},expression:"dot_property"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{staticClass:"mr-3 to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t(
                                                    'receptions.modify_article.quantity'
                                                ),"clearable":true,"clear-icon":"mdi-close","disabled":_vm.pendingReceivedQuantity() <= 0,"dense":_vm.is_boxes,"outlined":_vm.is_boxes},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.addDotToDataTable()},function($event){return _vm.handleInput($event, 'int', 6)}]},model:{value:(_vm.quantity),callback:function ($$v) {_vm.quantity=$$v},expression:"quantity"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.pendingReceivedQuantity() -
                                                    parseInt(_vm.quantity) <
                                                    0},on:{"click":function($event){return _vm.addDotToDataTable()}}},[_vm._v(" "+_vm._s(_vm.$t( "receptions.modify_article.add" ))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-plus-thick")])],1)],1)],1)],1)]},proxy:true},{key:"item",fn:function(ref){
                                                    var item = ref.item;
                                                    var index = ref.index;
return [_c('tr',{on:{"mouseenter":function($event){_vm.hoveredRow = item.dot_property},"mouseleave":function($event){_vm.hoveredRow = null}}},[_c('td',[_vm._v(_vm._s(item.dot_property))]),_c('td',[_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                                            _vm.editDotQuantityId !==
                                                item.dot_property
                                        ),expression:"\n                                            editDotQuantityId !==\n                                                item.dot_property\n                                        "}],staticClass:"cursor-pointer",on:{"dblclick":function($event){return _vm.switchToEditInput(item)}}},[_vm._v(_vm._s(item.quantity)+" "),(
                                                _vm.hoveredRow === item.dot_property
                                            )?_c('v-icon',{staticClass:"ml-2",attrs:{"color":"blue","right":""},on:{"click":function($event){return _vm.switchToEditInput(item)}}},[_vm._v(" mdi-pencil")]):_vm._e()],1),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(
                                            _vm.editDotQuantityId ===
                                                item.dot_property
                                        ),expression:"\n                                            editDotQuantityId ===\n                                                item.dot_property\n                                        "}],staticStyle:{"width":"30px"},attrs:{"id":'dot_quantity_' + item.dot_property},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleDotUpdate(item)},function($event){return _vm.handleInput($event, 'int', 4)}],"blur":function () {
                                                _vm.handleDotUpdate(item);
                                                _vm.editDotQuantityId = null;
                                            }},model:{value:(item.new_quantity),callback:function ($$v) {_vm.$set(item, "new_quantity", $$v)},expression:"item.new_quantity"}})],1),_c('td',[_vm._v(_vm._s(item.created_at))]),_c('td',[_c('v-icon',{staticClass:"hover-icon",attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteDotItem(index)}}},[_vm._v(" mdi-delete ")])],1)])]}}])})],1)],1)],1)],1),(_vm.showReceptionDetail)?_c('ModifyReceptionDetails',{attrs:{"data":_vm.receptionDetail,"rec_statuses":_vm.reception_order_statuses},on:{"change-popup-model-visibility":_vm.onChangePopupModelVisibility}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }