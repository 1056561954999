<template>
<div>
  <v-data-table
      :headers="tableHeaders"
      :items="formattedEmails"
      :hide-default-footer="true"
      :disable-pagination="true"
  >
    <!--   Item Slot-->
    <template v-slot:item="props">
      <tr :class="{
          'bg-red': ['permanent_failure', 'temporary_failure'].includes(props.item.status.key),
          'bg-green': ['sent', 'delivered'].includes(props.item.status.key),
        }"
          style="cursor: pointer"
          @click="handleRowClick(props.item)"
      >
        <td>{{ props.item.serial }}</td>
        <td>{{ props.item.title }}</td>
        <td>{{ props.item.from }}</td>
        <td>{{ props.item.to }}</td>
        <td>{{ props.item.subject }}</td>
        <td>
          <v-tooltip v-if="isset(props.item.attachments)" top>
            <template v-slot:activator="{ on, attrs }">
                <span v-on="on" v-bind="attrs">
                  <v-icon>mdi-attachment</v-icon>
                </span>
            </template>
            <span>{{ props.item.attachments }}</span>
          </v-tooltip>

        </td>
        <td>{{ formatDateTime(props.item.date) }}</td>
        <td>{{ props.item.status_text }}</td>

      </tr>
    </template>
  </v-data-table>
  <email-detail v-model="emailDialog" :email-id="selectedEmailId"></email-detail>
</div>
</template>
<script>
import EmailDetail from "@/components/Emails/EmailDetail.vue";

export default {
  name: 'EmailTable',
  components: {EmailDetail},
  props:{
    items:{
      required: true
    }
  },
  data(){
    return {
      selectedEmailId: null,
      emailDialog: false,
      tableHeaders: [
        {
          text: this.$t('srno'),
          value: 'serial',
          width: 50,
          sortable: false
        },
        {
          text: this.translate('reference'),
          value: 'title',
          sortable: false
        },
        {
          text: this.translate('from'),
          value: 'from',
          sortable: false
        },
        {
          text: this.translate('to'),
          value: 'to',
          sortable: false
        },
        {
          text: this.translate('subject'),
          value: 'subject',
          sortable: false
        },
        {
          text: this.translate('attachments_short'),
          value: 'attachments',
          sortable: false
        },
        {
          text: this.translate('date'),
          value: 'date',
          sortable: false
        },
        {
          text: this.translate('status'),
          value: 'status_text',
          sortable: false
        },
      ],
    }
  },
  computed:{
    formattedEmails(){
      if(!this.isset(this.items)){
        return [];
      }

      return this.items.map( (email, index) => {
        return {
          ...email,
          serial: index + 1,
          date: this.formatDate(email.created_at),
          status_text: this.translate('statuses.' + email.status.key),
          from: email.user.name,
          attachments: this.translatedAttachments(email)

        }
      })
    }
  },
  methods: {
    translate(key) {
      return this.$t('emails_section.' + key);
    },
    translatedAttachments(email){
      if(!this.isset(email?.attachments)){
        return "";
      }
      return email?.attachments.split(',').map((item) => {
        return this.translationHelper('send_pdf_via_email.documents.' + item)
      }).join(', ')
    },
    handleRowClick(row) {
      this.selectedEmailId = row.id;
      this.emailDialog = false;

      setTimeout(() => {
        this.emailDialog = true;
      }, 20);
    },
  },
}
</script>
<style>
.bg-red {
  background-color: #FBDCDC !important;
}
.bg-green {
  background-color: #DBF8DC !important;
}
</style>