import _axios from "@/plugins/axios";
import {API_BASE_URL} from "@/config";

export default {
    mounted() {
        this.getAddressBooks()
    },
    data(){
        return {
            addressBooks: [],
        }
    },
    methods: {
        getAddressBooks() {
            _axios.get(API_BASE_URL + `/user_address_book`, {
                params: {
                    page: this.page,
                    per_page: this.perPage,
                    search: this.search
                }
            })
                .then(response => {
                    this.addressBooks = response.data.data
                    this.totalItems = response.data.total
                    this.formatItems()
                })
        },
    }
}