<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col class="pa-0 ma-0" cols="12" sm="9"> </v-col>
        <v-col class="pa-0 ma-0" cols="12" sm="3">
          <v-text-field
            :autocomplete="autocompleteValueLocal"
            class="mr-3 to-upper"
            autofocus
            prepend-icon="mdi-magnify"
            v-bind:clearable="true"
            clear-icon="mdi-close"
            v-model="searchUser"
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-data-table
          fixed-header
          :footer-props="{
            'items-per-page-options': dataTableOptions,
          }"
          :headers="headers"
          :options="options"
          :server-items-length="totalItems"
          :loading-text="this.$t('loading')"
          class="elevation-0"
          :height="'calc(-200px + 100vh)'"
          style="width: 100%"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items-per-page="itemsPerPage"
          item-key="id"
          @update:sort-by="updateSort"
          @update:sort-desc="updateSortOrder"
          @update:page="updatePagination"
          @update:items-per-page="updateItemsPerPage"
          :items="users"
          must-sort
          :key="forceReloadComponentKey"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td @click="editUser(item)">
                {{ item.name }}
              </td>
              <td @click="editUser(item)">
                {{ item.email }}
              </td>
              <td @click="editUser(item)">
                {{ isset(item.role.company_id) ? item.role.name : $t('user_rights_section.role_titles.' + item.role.name) }}
              </td>
              <td @click="editUser(item)">
                {{ item.language_id }}
              </td>
              <td>
                <v-icon v-if="item.is_active" color="success" class="mr-1"
                  >mdi-check</v-icon
                >
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import axios from "@/plugins/axios";
import _ from "lodash";
import { API_BASE_URL } from "@/config";
import { VIEW_MODE } from "@/config";
import { EDIT_MODE } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";

export default {
  name: "User",
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      users: [],
      searchUser: null,
      isLoading: true,
      totalItems: 0,
      lastPage: 1,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      clients: [],
      options: {},
      sortBy: "name",
      sortDesc: false,
      sortOrd: "ASC",
      sort: {},
      showAlert: false,
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      search: null,
      autocompleteValueLocal: "off",
    };
  },

  mounted() {
    this.$store.commit("setFormMode", VIEW_MODE);
    this.fetchData();
    this.autocompleteValueLocal = this.autocompleteValue();
  },
  methods: {
    fetchData(currentPage = null) {
      this.resetDataTable(currentPage);
      
      axios
        .get(
          API_BASE_URL +
            "/users" +
            "?page=" +
            (this.page ?? 1) +
            (this.searchUser ? "&search=" + encodeURIComponent(this.searchUser) : "") +
            (this.itemsPerPage ? "&items_per_page=" + this.itemsPerPage : "") +
            (this.sortBy ? "&sortby=" + this.sortBy : "") +
            (this.sortOrd ? "&sortOrd=" + this.sortOrd : ""),
          { headers: this.header }
        )
        .then((resUsers) => {
          this.users = resUsers.data.data;
          this.totalItems = resUsers.data.data.length;
        })
        .catch((error) => {})
        .finally(() => {
          
        });
    },
    updatePagination(page) {
      this.page = page;
      this.fetchData(page);
    },
    updateItemsPerPage(itemsPerPage) {
      itemsPerPage == -1
        ? (this.itemsPerPage = this.totalItems)
        : (this.itemsPerPage = itemsPerPage);
      this.fetchData();
    },
    editUser(item) {
      this.$store.commit("seteditId", item.id);
      this.$store.commit("setFormMode", EDIT_MODE);
      this.$router.push("/users/edit").catch(() => {});
    },
    updateSort(sortBy) {
      console.log("data =>", sortBy);
      if (typeof sortBy == "object") {
        this.sortBy = sortBy[0];
      } else {
        this.sortBy = sortBy;
      }
      this.fetchData();
    },
    updateSortOrder(sortDesc) {
      if (typeof sortDesc == "object") {
        !sortDesc[0] ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      } else {
        !sortDesc ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      }
      this.fetchData();
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("users.name"),
          value: "name",
          sortable: true,
          width: 150,
        },
        {
          text: this.$t("users.email"),
          value: "email",
          sortable: true,
          width: 300,
        },
        {
          text: this.$t("users.role"),
          value: "id",
          sortable: false,
          width: 200,
        },
        {
          text: this.$t("users.language"),
          value: "language_id",
          sortable: true,
          width: 120,
        },
        {
          text: this.$t("users.status"),
          value: "is_active",
          sortable: true,
          width: 100,
        },
      ];
    },
  },
  watch: {
    searchUser: _.debounce(function(e) {
      this.fetchData();
    }, 500),
  },
};
</script>
<style scoped></style>
