<template>
  <v-dialog width="600px" :value="value">
    <v-card>
      <v-card-title>
        {{ $t('address_book_section.label') }}
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="12">
            <v-text-field
                autocomplete="off"
                class="mr-3 to-upper"
                :label="$t('vehicles.search')"
                autofocus
                prepend-icon="mdi-magnify"
                v-bind:clearable="true"
                clear-icon="mdi-close"
                v-model="search"
                @input="searchHandler"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-simple-table class="w-100">
            <thead>
            <tr>
              <th>{{ $t('articles.name') }}</th>
              <th>{{ $t('clients.email') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in addressBooks.filter(i => i.email)" :key="item.id" @click="select(item)">
              <td>{{ item.name }}</td>
              <td>{{ item.email }}</td>
            </tr>
            </tbody>
          </v-simple-table>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <span class="mx-2" @click="close"><CloseButton/></span>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import CloseButton from "@/components/Buttons/CloseButton.vue";
import addressBookMixin from "@/mixins/addressBookMixin";

export default {
  name: 'AddressBookSelector',
  components: {CloseButton},
  emits: ['input', 'selected'],
  mixins: [addressBookMixin],
  props: {
    value: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      search: null,
      timerId: null,
    }
  },
  watch: {
    value(val) {
      this.selected = val
    }
  },
  methods: {
    close() {
      this.$emit('input', false)
    },
    select(item) {
      this.close()
      this.$emit('selected', item)
    },
    searchHandler() {
      if (this.timerId) {
        clearTimeout(this.timerId)
      }
      this.timerId = setTimeout(() => {
        this.getAddressBooks()
      }, 500)
    }
  }
}
</script>