<template>
    <div>
        <!-- Driver -->
        <div>
            <span style="color: #2196F3"> {{ $t('billings.driver') }} </span>
            <v-row>
                <v-col cols="12" :sm="isClientCompanySelected ? '1' : '1'">
                    <v-select
                        :autocomplete="autocompleteValueLocal"
                        v-model="driver_family_client"
                        :items="familyClients"
                        :disabled="isDisabled"
                        item-value="id"
                        item-text="name"
                        menu-props="auto"
                        :label="$t('vehicles.clients_model.family_client')"
                        :outlined="is_boxes"
                        :dense="is_boxes"
                        @change="emitDriverValues()"
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="1  ">
                    <v-select
                        :autocomplete="autocompleteValueLocal"
                        v-model="driver_entity_type"
                        :items="entityTypeSelectItems"
                        :disabled="isDisabled"
                        item-text="text"
                        class="to-upper"
                        item-value="id"
                        return-object
                        :label="$t('vehicles.clients_model.entity_type')"
                        :outlined="is_boxes"
                        :dense="is_boxes"
                        @change="emitDriverValues()"
                    ></v-select>
                </v-col>
                <v-col cols="12" :sm="isClientCompanySelected ? '2' : '2'">
                    <v-combobox
                        class="to-upper"
                        :autocomplete="autocompleteValueLocal"
                        v-model="driver_name"
                        :items="driver_names"
                        item-value="id"
                        item-text="name"
                        :label="isClientCompanySelected ? $t('billings.comp_name') : $t('billings.family_name')"
                        required
                        @click:append="removeDriver()"
                        @change="emitDriverValues()"
                        append-icon="mdi-close"
                        v-on:keydown="handleInput($event, 'everything', 50)"
                        :search-input.sync="search_driver"
                        no-filter
                        v-on:keydown.enter.prevent=""
                        return-object
                        :outlined="is_boxes"
                        :dense="is_boxes"
                        >
                        <template slot="item" slot-scope="{ item }">
                            <v-list-item-content>
                            <p class="client_name">{{ item.name }} {{ item.first_name }}</p>
                            <p class="client_detail" v-if="item.representative_company_id">
                                Company
                            </p>
                            <p class="client_detail" v-else>Private</p>
                            <p class="client_detail">{{ item.email }}</p>
                            </v-list-item-content>
                        </template>
                    </v-combobox>
                </v-col>
                <v-col cols="12" v-if="! isClientCompanySelected" :sm="isClientCompanySelected ? '4' : '2'">
                    <v-text-field
                        :autocomplete="autocompleteValueLocal"
                        v-model="driver_first_name"
                        class="to-upper"
                        ref="client_name"
                        v-on:keydown="handleInput($event,'everything',50);"
                        :label="$t('vehicles.clients_model.client_first_name') "
                        :outlined="is_boxes"
                        :dense="is_boxes"
                        @change="emitDriverValues()"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" v-if="isClientCompanySelected">
                    <v-select
                        :autocomplete="autocompleteValueLocal"
                        v-model="driver_denomination"
                        :items="denominations"
                        item-value="id"
                        class="to-upper"
                        item-text="text"
                        :label="$t('vehicles.clients_model.denomination')"
                        :outlined="is_boxes"
                        :dense="is_boxes"
                        @change="emitDriverValues()"
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="1" v-if="isClientCompanySelected">
                    <v-text-field
                        :autocomplete="autocompleteValueLocal"
                        v-model="driver_tva"
                        @change="(driver_tva ? verifyTVA(driver_tva) : ''); emitDriverValues();"
                        ref="driver_tva"
                        class="to-upper"
                        required
                        v-on:keydown="handleInput($event,'justalphanumeric',45)"
                        :label="$t('vehicles.clients_model.tva')"
                        :outlined="is_boxes"
                        :dense="is_boxes"
                        :error-messages="
                            $store.state.validation && !$v.driver_tva.required
                                ? [this.$t('billings.text_messages.tva_required')]
                                : []
                        "
                    >
                        <template v-slot:prepend-inner>
                            <template v-if="isVerifiedTVALoading">
                            <v-progress-circular
                                indeterminate
                                size="20"
                                width="2"
                                color="primary"
                            ></v-progress-circular>
                            </template>
                            <template v-else>
                            <v-icon :color="isVerifiedTVA == false ? 'red' : 'green'">
                                {{ isVerifiedTVA ? 'mdi-checkbox-marked-circle' : (isVerifiedTVA == false ? 'mdi-close-circle' : '') }}
                            </v-icon>
                            </template>
                        </template>

                        <template #label>
                            <span class="red--text"><strong>* </strong></span> {{ $t('vehicles.clients_model.tva') }}
                        </template>
                        <template  #append>
                            <v-icon  @click="verifyTVA(driver_tva)">mdi-refresh</v-icon>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col class="d-flex">
                    <v-text-field
                        :autocomplete="autocompleteValueLocal"
                        v-model="driver_email"
                        class="to-upper"
                        v-on:keydown="handleInput($event,'email',60);"
                        :label="$t('vehicles.clients_model.email')"
                        :outlined="is_boxes"
                        :dense="is_boxes"
                        @change="emitDriverValues()"
                    ></v-text-field>
                </v-col>
                <template v-if="parentData && parentData.id == 28093">
                    <!-- GSM -->
                    <v-col class="d-flex">
                        <vue-tel-input-vuetify 
                            :disabledFetchingCountry="true" 
                            :preferredCountries="preferredCountry" 
                            :filterCountries="onlyCountries"  
                            validCharactersOnly 
                            :label="$t('vehicles.clients_model.gsm')" 
                            :inputOptions="{ showDialCode: true, tabindex: 0 }" 
                            :mode="'international'" 
                            v-model="driver_gsm"
                            @change="emitDriverValues()"
                        ></vue-tel-input-vuetify>
                    </v-col>
                    <!-- Phone -->
                    <v-col class="d-flex">
                        <vue-tel-input-vuetify 
                            :disabledFetchingCountry="true" 
                            :preferredCountries="preferredCountry" 
                            :filterCountries="onlyCountries"  
                            validCharactersOnly 
                            :label="$t('vehicles.clients_model.phone')" 
                            :inputOptions="{ showDialCode: true, tabindex: 0 }" 
                            :mode="'international'" 
                            v-model="driver_phone"
                            @change="emitDriverValues()"
                        ></vue-tel-input-vuetify>
                    </v-col>
                </template>
                <template v-else>
                    <!-- Phone -->
                    <v-col class="d-flex">
                        <vue-tel-input-vuetify 
                            :disabledFetchingCountry="true" 
                            :preferredCountries="preferredCountry" 
                            :filterCountries="onlyCountries"  
                            validCharactersOnly 
                            :label="$t('vehicles.clients_model.phone')" 
                            :inputOptions="{ showDialCode: true, tabindex: 0 }" 
                            :mode="'international'" 
                            v-model="driver_phone"
                            @change="emitDriverValues()"
                        ></vue-tel-input-vuetify>
                    </v-col>
                    <!-- GSM -->
                    <v-col class="d-flex">
                        <vue-tel-input-vuetify 
                            :disabledFetchingCountry="true" 
                            :preferredCountries="preferredCountry" 
                            :filterCountries="onlyCountries"  
                            validCharactersOnly 
                            :label="$t('vehicles.clients_model.gsm')" 
                            :inputOptions="{ showDialCode: true, tabindex: 0 }" 
                            :mode="'international'" 
                            v-model="driver_gsm"
                            @change="emitDriverValues()"
                        ></vue-tel-input-vuetify>
                    </v-col>
                </template>
                <!-- Language -->
                <v-col cols="12" :sm="isClientCompanySelected ? '1' : '1'" >
                    <v-autocomplete
                        class="to-upper"
                        :autocomplete="autocompleteValueLocal"
                        v-model="driver_language"
                        :items="languages"
                        item-value="id"
                        item-text="text"
                        :label="$t('vehicles.clients_model.languages')"
                        :outlined="is_boxes"
                        :dense="is_boxes"
                        @change="emitDriverValues()"
                    ></v-autocomplete>
                </v-col>
            </v-row>
        </div>
        <!-- Driver Member -->
        <div v-if="showMembers">
            <span style="color: #2196F3"> {{ $t('billings.driver_member') }} 
                <ClientReclamation :note.sync="member_note"></ClientReclamation>
            </span>
            <v-row class="mb-0 pb-0">
                <!-- Member Types -->
                <v-col class="d-flex">
                    <v-select
                        :autocomplete="autocompleteValueLocal"
                        v-model="member_type"
                        return-object
                        :items="member_types"
                        item-value="id"
                        item-text="text"
                        menu-props="auto"
                        :label="$t('vehicles.clients_model.member_type')"
                        :outlined="is_boxes"
                        :dense="is_boxes"
                        hide-details="true"
                        @change="emitDriverValues()"
                    ></v-select>
                </v-col>
                <!-- Member Department Name -->
                <v-col class="d-flex">
                    <v-combobox
                        class="to-upper"
                        :autocomplete="autocompleteValueLocal"
                        v-model="member_department"
                        :items="member_departments"
                        item-value="id"
                        item-text="name"
                        :label="$t('vehicles.clients_model.department')"
                        required
                        v-on:keydown="handleInput($event, 'alphanumeric', 50)"
                        :search-input.sync="search_department"
                        no-filter
                        v-on:keydown.enter.prevent=""
                        return-object
                        :outlined="is_boxes"
                        :dense="is_boxes"
                        @change="emitDriverValues()"
                        >
                        <template slot="item" slot-scope="{ item }">
                            <v-list-item-content>
                            <p class="client_name">{{ item }}</p>
                            </v-list-item-content>
                        </template>
                    </v-combobox>
                </v-col>
                <!-- Member Name -->
                <v-col cols="12" sm="2">
                    <v-combobox
                        class="to-upper"
                        :autocomplete="autocompleteValueLocal"
                        v-model="member_name"
                        :items="member_names"
                        item-value="id"
                        item-text="name"
                        :label="$t('clients.member_name')"
                        required
                        append-icon="mdi-close"
                        @click:append="removeMember"
                        v-on:keydown="handleInput($event, 'everything', 50)"
                        :search-input.sync="search_member"
                        v-on:keydown.enter.prevent=""
                        return-object
                        :outlined="is_boxes"
                        :dense="is_boxes"
                        @change="emitDriverValues()"
                    >
                        <template slot="item" slot-scope="{ item }">
                            <v-list-item-content>
                            <p class="client_name">{{ item.name }} {{ item.first_name }}</p>
                            <!-- <p class="client_detail" v-if="item.representative_company_id">
                                {{ $t('company') }}
                            </p>
                            <p class="client_detail" v-else>
                                {{ $t('private') }}
                            </p> -->
                            <p class="client_detail">{{ item.email }}</p>
                            </v-list-item-content>
                        </template>
                    </v-combobox>
                </v-col>
                <!-- Memeber First Name -->
                <v-col cols="12" :sm="2">
                    <v-text-field
                        :autocomplete="autocompleteValueLocal"
                        v-model="member_first_name"
                        name="name"
                        class="to-upper"
                        ref="client_name"
                        v-on:keydown="handleInput($event,'everything',50);"
                        :label="$t('vehicles.clients_model.client_first_name')"
                        :outlined="is_boxes"
                        :dense="is_boxes"
                        @change="emitDriverValues()"
                    ></v-text-field>
                </v-col>
                <!-- Member Email -->
                <v-col class="d-flex">
                    <v-text-field
                        :autocomplete="autocompleteValueLocal"
                        v-model="member_email"
                        class="to-upper"
                        v-on:keydown="handleInput($event,'email',60);"
                        :label="$t('vehicles.clients_model.email')"
                        :outlined="is_boxes"
                        :dense="is_boxes"
                        @change="emitDriverValues()"
                    ></v-text-field>
                </v-col>
                <!-- Member Phone -->
                <v-col class="d-flex">
                    <vue-tel-input-vuetify 
                        :disabledFetchingCountry="true" 
                        :preferredCountries="preferredCountry" 
                        :filterCountries="onlyCountries"  
                        validCharactersOnly 
                        :label="$t('billings.phone')" 
                        :inputOptions="{ showDialCode: true, tabindex: 0 }" 
                        :mode="'international'" 
                        v-model="member_phone"
                        @change="emitDriverValues()"
                    ></vue-tel-input-vuetify>
                </v-col>
                <!-- Member GSM -->
                <v-col class="d-flex">
                    <vue-tel-input-vuetify 
                        :disabledFetchingCountry="true" 
                        :preferredCountries="preferredCountry" 
                        :filterCountries="onlyCountries" 
                        validCharactersOnly 
                        :label="$t('billings.gsm')" 
                        :inputOptions="{ showDialCode: true, tabindex: 0 }" 
                        :mode="'international'" 
                        v-model="member_gsm"
                        @change="emitDriverValues()"
                    ></vue-tel-input-vuetify>
                </v-col>
                <!-- Member Language -->
                <v-col class="d-flex">
                    <v-autocomplete
                        class="to-upper"
                        :autocomplete="autocompleteValueLocal"
                        role="presentation"
                        flat
                        :items="languages"
                        v-model="member_language"
                        :label="$t('billings.languages')"
                        item-value="id"
                        item-text="text"
                        v-on:keydown.enter.prevent=""
                        :outlined="is_boxes"
                        :dense="is_boxes"
                        hide-no-data
                        hide-details
                        @change="emitDriverValues()"
                    ></v-autocomplete>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script>
import axios from '@/plugins/axios';
import _ from 'lodash';
import {API_BASE_URL} from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from '@/config';
import { validationMixin } from 'vuelidate'
import { required, email, requiredIf, phoneNum, numeric ,minLength, maxLength, alpha, minValue, maxValue } from 'vuelidate/lib/validators'
import VueTelInputVuetify from "@/components/PhoneNumberInput";
import ClientReclamation from '@/components/Reclamation.vue';

export default {
        mixins: [validationMixin],
        name:       "CreateEditDriver",
        components: {
            "vue-tel-input-vuetify": VueTelInputVuetify,
            ClientReclamation
        },
        props: {
            comp_driver: {
                type: Object,
                default: null
            },
            comp_driver_member: {
                type: Object,
                default: null
            },
            parentData:{
                type: Object,
                default: null
            }
        },
        validations: {
            driver_tva: {
                required: requiredIf(function(model) {
                    return this.driver_entity_type.id == 1;
                }),
            },
        },
        data() {
            return {
                is_boxes: this.getCompanyPropertyFromLocalStorage('boxes_on_fields', 1),
                entityTypes: [{ id: 0, value: 'Private' }, { id: 1, value: 'Company' }],
                autocompleteValueLocal : 'off',
                token: this.$store.state.AccessToken,
                header: { Authorization: 'Bearer ' + this.$store.state.AccessToken },
                denominations: [],
                languages: [],

                driver_id: null,
                driver_representative_company_id: null,
                driver_family_client: null,
                driver_entity_type: null,
                search_driver: null,
                driver_name: null,
                driver_first_name: null,
                driver_names: [],
                driver_denomination: null,
                driver_tva: null,
                driver_email: null,
                driver_phone: null,
                driver_gsm: null,
                driver_language: null,


                search_member: null,
                search_department: null,
                member_type: null,
                member_types: [],
                member_name: null,
                member_names: [],
                member_id: null,
                member_first_name: null,
                member_department: null,
                member_departments: [],
                member_phone: null,
                member_gsm: null,
                member_email: null,
                member_language: null,
                member_note: null,

            }
        },
        activated(){
        },
        beforeMount(){
        },
        async mounted(){
            this.getCompanyDenominations();
            await this.getMemberTypes();
            this.getCurrencies();
            this.getLanguages().then(() => {
                if(! this.driver_entity_type){
                    this.setDefaultEntityType();
                }
            });
        },
        methods: {
            setDefaultDenomination(){
                this.driver_denomination = this.denominations[0];
            },
            setDefaultValues(){
                this.setDefaultEntityType();
                this.setDefaultFamilyClient();      
                this.setDefaultMemberType();
            },
            setDefaultEntityType(){
                this.driver_entity_type = this.selectEntityType('private');
            },
            setDefaultMemberType(){
                this.member_type = this.member_types[0];
            },
            fetchDriversListDebounced() {
                if (!this.search_driver || typeof this.search_driver == "object") {
                    return;
                }
                // This means it is in edit mode
                axios
                .get(
                    API_BASE_URL +
                    "/clients/search?companiesOnly=true&searchRegNumber=true" +
                    (this.search_driver ? "&search=" + encodeURIComponent(this.search_driver) : ""),
                    { headers: this.header }
                )
                .then(({ data }) => {
                    data.data.forEach((element) => {
                    element.clientname =
                        element.name + " " + (element.first_name ?? "");
                    });
                    this.driver_names = data.data;
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    // 
                });
            },

            fetchDepartmentsListDebounced() {
                if (!this.search_department || typeof this.search_department == "object") {
                    return;
                }
                // This means it is in edit mode
                axios
                .get(
                    API_BASE_URL +
                    "/clients/departments?search=" + this.search_department,
                    { headers: this.header }
                )
                .then(({ data }) => {
                    this.member_departments = data.data;
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    // 
                });
            },
            getMembers(representative_company_id){
                if(representative_company_id){
                    axios
                    .get(
                        API_BASE_URL +
                            "/clients?searchRegNumber=true&membersOnly=true" +
                            ("&representative_company_id=" + representative_company_id),
                        {
                            headers: this.header,
                        }
                    )
                    .then(({ data }) => {
                        this.member_names = data.data;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                }
            },
            removeDriver(){
                this.driver_id                          = null;
                this.driver_representative_company_id   = null;
                this.driver_family_client               = null;
                this.driver_entity_type                 = null;
                this.search_driver                      = null;
                this.driver_name                        = null;
                this.driver_first_name                  = null;
                this.driver_names                       = [];
                this.driver_denomination                = null;
                this.driver_tva                         = null;
                this.driver_email                       = null;
                this.driver_phone                       = null;
                this.driver_gsm                         = null;
                this.driver_language                    = this.userLanguageId;

                this.removeMember();
                this.setDefaultValues();
                this.emitDriverValues();
            },
            removeMember(){
                this.member_names           = [];
                this.member_name            = null;
                this.member_id              = null;
                this.member_first_name      = null;
                this.member_department      = null;
                this.member_type            = null;
                this.member_email           = null;
                this.member_phone           = null;
                this.member_gsm             = null;
                this.member_note            = null;
                this.member_language        = this.userLanguageId;
                this.setDefaultMemberType();
                this.emitDriverValues();
                
            },
            getMemberTypes(){
                return new Promise((resolve, reject) => {
                    this.getCodes('member_types', 'member_types')
                    .then((data) => {
                        this.member_types = data.filter((element) => {
                            return element.key != 'not_specified'
                        })
                        this.member_type = this.member_types[0];
                        resolve();
                    });
                })
            },
            setDefaultFamilyClient(){
                this.familyClients.forEach(element => {
                    if(element.is_default == 1){
                        this.driver_family_client = element;
                    }
                });
                
            },
            async getCompanyDenominations() {
                await this.getCodes('company_denomination', 'company_denomination')
                    .then((data) => {
                        this.denominations = data;
                    });
            },
            getCurrencies() {
                return new Promise((resolve, reject) => {
                    this.getDataWithCache('/currencies', 'currencies', 'currencies', true).then(() => {
                        resolve();
                    });
                })
            },
            getLanguages() {
                return new Promise((resolve, reject) => {
                    this.getDataWithCache('/languages', 'languages', 'languages', true).then((data) => {
                        resolve(data);
                    });
                })
            },
            getClient(id) {
                return new Promise((resolve) => {
                    
                    axios.get(API_BASE_URL + '/clients/' + id, {headers: this.header})
                    .then(response => {
                        let client = response.data.data;
                        this.driver_names = [client];
                        this.driver_name = client;
                        resolve(client);
                    })
                    .catch((error) =>{
                        resolve();
                    })
                    .finally(() => {
                        
                    });
                })
            },
            emitDriverValues(justDriver = false){
                this.$nextTick(() => {
                    if(justDriver){
                        this.$emit("update:comp_driver", this.driverData);
                    }
                    else{
                        this.$emit("update:comp_driver", this.driverData);
                        this.$emit("update:comp_driver_member", this.driverMemberData);
                    }
                })
            },
            setDriverValues(val){
                if(val.id){
                    if(val.representative_company_id || val.denomination_id || val.tva){
                        if(val.representative_company_id){
                            this.getMembers(val.representative_company_id);
                        }
                        this.driver_entity_type = this.selectEntityType('company');
                        this.driver_representative_company_id = val.representative_company_id;
                    }
                    else{
                        this.driver_entity_type = this.selectEntityType('private');
                    }
                }
                if(val.company_id && !val.company){
                    this.fetchDriversListDebounced();
                }
                if(val.company && val.company.denomination_id){
                    this.$nextTick(() => {
                        this.driver_denomination = val.company.denomination_id;
                        this.driver_tva = val.company.tva;
                        this.verifyTVA(this.driver_tva);
                    })
                }
                this.driver_id = val.id;
                this.driver_language = val.language_id ? (typeof val.language_id == 'object' ? val.language_id.id : val.language_id) : this.userLanguageId;
                this.driver_first_name = val.first_name;
                this.driver_phone = val.phone;
                this.driver_gsm = val.gsm;
                this.driver_email = val.email;
                this.driver_family_client = val.family_client_id;
            }
        },
        computed: {
            isDisabled(){
                return this.driver_name ? (typeof this.driver_name == 'object' && this.driver_name.id ? true : false) : false;
            },
            driverData(){
                let data = null;
                if(this.driver_name){
                    data = {
                        "id":                       this.driver_id,
                        "language_id":              this.driver_language,
                        "name":                     this.driver_name ? (typeof this.driver_name == 'object' ? this.driver_name.name : this.driver_name) : null,
                        "first_name":               this.driver_first_name ? this.driver_first_name : null,
                        "phone":                    this.driver_phone,
                        "gsm":                      this.driver_gsm,
                        "email":                    this.driver_email,
                        "family_client_id":         this.driver_family_client ? (typeof this.driver_family_client == 'object' ? this.driver_family_client.id : this.driver_family_client) : null,
                        "tva":                      this.isClientCompanySelected ? this.driver_tva : null,
                        "denomination_id":          this.isClientCompanySelected ? (typeof this.driver_denomination == 'object' ? this.driver_denomination.id : this.driver_denomination) : null
                    };
                }
                else{
                    data = null;
                }
                return data;
            },
            driverMemberData(){
                let data = null;
                if(this.member_name){
                    data = {
                        "id":                       this.member_id,
                        "type_id":                  this.member_type ? (typeof this.member_type == "object" ? this.member_type.id : this.member_type) : null,
                        "department":               this.member_department,
                        "language_id":              this.member_language ? (typeof this.member_language == "object" ? this.member_language.id : this.member_language) : null,
                        "name":                     this.member_name ? (typeof this.member_name == 'object' ? this.member_name.name : this.member_name) : null,
                        "first_name":               this.member_first_name ? this.member_first_name : null,
                        "phone":                    this.member_phone,
                        "gsm":                      this.member_gsm,
                        "note":                     this.member_note,
                        "email":                    this.member_email,
                    };
                }
                else{
                    data = null;
                }
                return data;
            },
            isClientCompanySelected () {
                if(this.driver_entity_type){
                    return this.driver_entity_type.id === 1;
                }
                return false;
            },
            showMembers(){
                if(this.driver_entity_type){
                    return this.driver_entity_type.id === 1;
                }
                return false;
            },
        },
        beforeDestroy(){
            this.$store.commit("setValidation",false);
        },
        watch:   {
            isClientCompanySelected(val){
                if(val){
                    this.setDefaultDenomination();
                }
                else{
                    this.driver_denomination = null;
                    this.driver_tva = null;
                }
                if(!this.driver_language){
                    this.driver_language = this.userLanguageId;
                }
                if(!this.member_language){
                    this.member_language = this.userLanguageId;
                }
            },
            familyClients:{
                handler: function(){
                    if(this.familyClients.length > 0){
                    this.setDefaultFamilyClient();
                    }
                }
            },
            driver_name:{
                handler: function(val){
                    if(val && typeof val == 'object'){
                        if(val.updated_at){
                            // Driver from Backend
                            this.setDriverValues(val);
                            setTimeout(() => {
                                this.emitDriverValues(true);
                            }, 1000);
                            this.emitDriverValues(true);
                        }
                        else{
                            // Driver from Front End
                            this.setDriverValues(val);
                        }
                    }
                },
                immediate: true
            },
            showDialog:{
                handler: function(val){
                    this.internalShowDialog = val;
                }
            },
            search_driver(val) {
                if (!val ) {
                    return;
                }
                clearTimeout(this._searchTimerId);
                this._searchTimerId = setTimeout(() => {
                    this.fetchDriversListDebounced();
                }, 500);
            },
            search_department(val) {
                if (!val ) {
                    return;
                }
                clearTimeout(this._searchTimerId);
                this._searchTimerId = setTimeout(() => {
                    this.fetchDepartmentsListDebounced();
                }, 500);
            },
            comp_driver:{
                handler: function(val) {
                    if(val && typeof val == 'object'){
                        this.driver_names = [val];
                        this.driver_name = val;
                    }
                    else if(! val){
                        this.removeDriver();
                    }
                },
                immediate: true
            },
            comp_driver_member:{
                handler: function(val) {
                    if(val && typeof val == 'object'){
                        this.getMemberTypeById(val.type_id).then((memberType) => {
                            this.member_type        = memberType;
                            this.member_name        = val.name;
                            this.member_id          = val.id;
                            this.member_first_name  = val.first_name;
                            this.member_department  = val.department;
                            this.member_email       = val.email;
                            this.member_phone       = val.phone;
                            this.member_gsm         = val.gsm;
                            this.member_note        = val.note;
                            this.member_language    = val.language_id;
                        })
                    }
                    else if(! val){
                        this.removeMember();
                    }
                },
                immediate: true
            },
            member_note(){
                this.emitDriverValues();
            }
        }
}
</script>