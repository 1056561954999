<template>
  <v-dialog :value="value" width="65%">
    <v-card height="70vh">
      <v-card-title class="mb-0">
        <span>{{ $t('pickup_address_map') }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="ma-0 pa-0">
        <template v-if="loading">
          <v-skeleton-loader type="list-item-three-line" v-for="i in 6" :key="i" :loading="loading"/>
        </template>
        <div id="map" :key="$store.state.forceReloadComponentKey" class="map" style='width: 100%; min-height: 70vh!important;' v-show="!loading"></div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import {Map, Marker, Popup, NavigationControl, } from 'maplibre-gl';
import _axios from "@/plugins/axios";
import axios from 'axios';
import {API_BASE_URL} from "@/config";


export default {
  name: "PickupAddressMap",
  emits: ['input'],
  props: {
    appointments: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mapCordinates: [],
      loading: false
    };
  },
  mounted() {
    //
  },
  watch: {
    async value() {
      if (this.value) {
        try {
          this.$store.state.forceReloadComponentKey++
          this.loading = true
          this.mapCordinates = []
          await this.processPickupAddresses()
          await this.reloadMap()
          this.loading = false
        } catch (error) {
          this.loading = false
        }
      }
    }
  },
  methods: {
    close() {
      this.$emit('input', false)
    },
    async processPickupAddresses() {
      let ids = this.appointments.map(e => e.pickup_address_id)
          .filter(i => i !== null)
          .filter((value, index, self) => self.indexOf(value) === index);
      let pickupAddresses = await _axios.get(API_BASE_URL + '/pickup_addresses', {
        params: {
          ids: ids.join(',')
        }
      });

      pickupAddresses = pickupAddresses.data.data

      let coordinates = pickupAddresses
          .filter((value, index, self) => self.findIndex(e => e.address_id === value.address_id) === index) // unique
          .map(e => {

            return {
              id: e.id,
              address_string: e.address.human_readable_address,
              count: pickupAddresses.filter(a => a.address_id === e.address_id).length,
              title: this.appointments.find(a => a.pickup_address_id === e.id).title,
              noCoordinates: e.address.lat === null || e.address.long === null,
              coordinates: [e.address.long, e.address.lat]
            }
          })

      let allPromises = [];
      coordinates.forEach((e, index) => {
        if (e.noCoordinates) {
          allPromises.push(this.geocode(e.address_string).then((result) => {
            if (result) {
              e.coordinates = [result.lon, result.lat]
              // get index of the element and  update coordinates
              coordinates[index].coordinates = [result.lon, result.lat]
              this.updatePickupAddress(e, result.lat, result.lon)
            }
          }))
        }
      })

      await Promise.all(allPromises);

      this.mapCordinates = coordinates;
    },
    // 77.216721,28.644800
    async reloadMap() {
      if (this.mapCordinates && this.mapCordinates.length > 0) {
        const allCoordinates = this.mapCordinates.map(e => e.coordinates);
        const zoomCenter = this.zoomCenter(allCoordinates);

        const mapOptions = {
          container: 'map',
          style: 'https://api.maptiler.com/maps/streets-v2/style.json?key=f4IJi1mRlspltChrcUM3',
          center: zoomCenter.center,
          zoom: zoomCenter.zoom,
        };

        setTimeout(() => {
          let map = new Map(mapOptions);
          map.addControl(new NavigationControl());
          this.mapCordinates.forEach(element => {
            // popup is open by default

            let popup = new Popup({
              closeOnMove: false,
              closeButton: false,
              closeOnClick: false,
              anchor: 'bottom',
              offset: -12
            })
                .setText(element.count);


            const markerElement = document.createElement('div');
            markerElement.className = 'custom-marker-icon';
            // increase  the z-index of the marker
            markerElement.style.zIndex = 1000;
            // add label which is the counter
            markerElement.innerHTML = `<p style="display: flex; justify-content: center; margin-top: 11px">${element.count}</p>`;
            new Marker({element: markerElement})
                .setLngLat(element.coordinates)
                .setPopup(popup)
                .addTo(map)
          });

          // map.on('load', () => {
          //   map.fitBounds(allCoordinates, {
          //     padding: 300,
          //   });
          //
          // });
        }, 2);
      }
    },
    async geocode(search) {
      return new Promise((resolve, reject) => {
        axios.get('https://api.geoapify.com/v1/geocode/search', {
          params: {
            text: search,
            apiKey: this.resolveAPIKey(),
            format: 'json'
          }
        })
            .then(response => {
              console.log(response.data)
              resolve(response.data?.results[0] ?? null);
            }).catch(error => reject(error));
      })
    },
    resolveAPIKey() {
     // get company property ADDRESS_LOCALISATION_API
      const localizer = this.getCompanyPropertyValue('ADDRESS_LOCALISATION_API');

      if(!localizer || localizer !== 'OPENSTREETMAP') {
        return process.env.VUE_APP_GEOAPIFY_API_KEY
      }

      return this.getCompanyPropertyValue('ADDRESS_LOCALISATION_KEY') ?? process.env.VUE_APP_GEOAPIFY_API_KEY
    },
    async updatePickupAddress(address, lat, long) {
      return new Promise((resolve, reject) => {
        _axios.put(API_BASE_URL + '/pickup_addresses/' + address.id, {
          lat: lat,
          lng: long
        })
      })
    },
    calculateBoundingBox(coordinates) {
      const lats = coordinates.map(coord => coord[1]);
      const lons = coordinates.map(coord => coord[0]);

      const maxLat = Math.max(...lats);
      const minLat = Math.min(...lats);
      const maxLon = Math.max(...lons);
      const minLon = Math.min(...lons);

      return { maxLat, minLat, maxLon, minLon };
    },

    calculateCenter(boundingBox) {
      const centerLat = (boundingBox.maxLat + boundingBox.minLat) / 2;
      const centerLon = (boundingBox.maxLon + boundingBox.minLon) / 2;

      return [centerLon, centerLat];
    },

    calculateZoomLevel(boundingBox, mapWidth, mapHeight) {
      const WORLD_DIM = { height: 256, width: 256 };
      const ZOOM_MAX = 21;

      function latRad(lat) {
        const sin = Math.sin(lat * Math.PI / 180);
        const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
      }

      function zoom(mapPx, worldPx, fraction) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
      }

      const latFraction = (latRad(boundingBox.maxLat) - latRad(boundingBox.minLat)) / Math.PI;
      const lonFraction = (boundingBox.maxLon - boundingBox.minLon) / 360;

      const latZoom = zoom(mapHeight, WORLD_DIM.height, latFraction);
      const lonZoom = zoom(mapWidth, WORLD_DIM.width, lonFraction);

      return Math.min(latZoom, lonZoom, ZOOM_MAX);
    },

    zoomCenter(coordinates) {
      const boundingBox = this.calculateBoundingBox(coordinates);
      const center = this.calculateCenter(boundingBox);
      let  mapElement = document.getElementById('map');
      const zoom = this.calculateZoomLevel(boundingBox, 1000, 350);

      return { center, zoom };
    }
  },
}
</script>
<style>
.custom-marker-icon {
  background-image: url('../../assets/images/marker_2.png'); /* Update with your icon path */
  background-size: cover;
  width: 60px; /* Adjust the width as needed */
  height: 60px; /* Adjust the height as needed */
  border-radius: 50%; /* Optional: make the icon circular */
}
</style>