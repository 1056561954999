<template>
    <div>
        <span :class="model_view ? 'ml-4' : 'ml-2'"  style="color: #2196F3"> {{ $t('resource_types.vehicle') }} </span>
        <span class="ml-2" v-if="registration_no && typeof registration_no ==='object' && registration_no.id">
            <ShowEditVehicleModal/>
        </span>
        <v-row class="mt-n2" :class="model_view ? 'ml-2 pt-1 pb-6 mr-2' : ''">
            <!-- Registration No -->
            <v-col cols="12" :sm="model_view ? 4 : ''" class="pt-0 pb-0" @click="comp_available_depots && comp_available_depots.length > 0 ? showPopUpForNotAllowedToEdit() : ''">
                <v-autocomplete
                    v-if="!isEditModeForRegistration && !registrationNumberIsUnknown"
                    :disabled="(comp_available_depots && comp_available_depots.length > 0)"
                    :autocomplete="autocompleteValueLocal"
                    :items="client_vehicles"
                    v-model="registration_no"
                    single-line
                    append-icon="mdi-close"
                    @click:append="registration_no = null; searchRegistrationNumber= null "
                    :label="$t('billings.registration_no')"
                    v-on:keydown="handleInput($event,'alphanumdashdot',15);"
                    item-text="registration_number"
                    item-value="id"
                    class="to-upper"
                    ref="registration_number"
                    :error-messages="registrationNumberErrorMessages"
                    :hide-no-data="!searchRegistrationNumber"
                    :search-input.sync="searchRegistrationNumber"
                    :loading="isLoadingSearchRegistrationNumber"
                    @blur="isNewItem && searchRegistrationNumber  && !isLoadingSearchRegistrationNumber ? createNewRegistration() : ''"
                    return-object
                    :hide-details="model_view ? true: false"
                >
                    <template v-slot:no-data>
                    <!-- New Registration Number -->
                    <div @click="createNewRegistration">
                        <v-list-item ripple v-if="isNewItem && searchRegistrationNumber && !isLoadingSearchRegistrationNumber">
                        <v-list-item-content>
                            <v-list-item-title style="cursor: pointer"> {{ $t('billings.create_new_vehicle') }}
                            <strong>{{ searchRegistrationNumber.toUpperCase() }}</strong></v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>
                    </div>
                    </template>
                    <template v-if="registration_no && typeof registration_no == 'object' && registration_no.id" v-slot:prepend>
                    <div v-if="! isEditModeForRegistration">
                        <v-tooltip top z-index="9999">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon @click="enterRegistrationFromEdit()" class="ma-0 mr-1" color="primary" v-bind="attrs" v-on="on">
                            mdi-pencil
                            </v-icon>
                        </template>
                        {{ $t('edit_current_vehicle') }}
                        </v-tooltip>
                    </div>
                    </template>
                    <template v-else v-slot:prepend>
                    <v-tooltip top z-index="99999">
                        <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                        <v-checkbox v-model="unknownRegistrationNumber" class="ma-0 mr-1" :hide-details="model_view ? true: false"></v-checkbox>
                        </span>
                        </template>
                        {{ $t('billings.unknown_reg_no') }}
                    </v-tooltip>
                    </template>
                    <!-- Selected Slot Registration Number -->
                    <template v-slot:selection="{ attrs, item }">
                    {{ item.registration_number }}
                    </template>
                    <!-- Registration Number List -->
                    <template v-slot:item="{ attrs, item }">
                    <v-row @click="selectRegistrationNumber(item)">
                        <v-col class="pa-4 ma-0" cols="12" sm="12">
                        {{ item.registration_number }}
                        </v-col>
                    </v-row>
                    </template>
                </v-autocomplete>
                <v-text-field :value="$t('billings.unknown_reg_no')" readonly v-if="registrationNumberIsUnknown" :hide-details="model_view ? true: false">
                    <template #prepend>
                    <v-tooltip top z-index="99999" v-if="$route.path.includes('add')">
                        <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                        <v-checkbox v-model="unknownRegistrationNumber" class="ma-0 mr-1"></v-checkbox>
                        </span>
                        </template>
                        {{ $t('billings.unknown_reg_no') }}
                    </v-tooltip>
                    <v-tooltip top z-index="9999" v-else>
                        <template v-slot:activator="{ on, attrs }">
                        <v-icon @click="enterRegistrationFromEdit()" class="ma-0 mr-1" color="primary" v-bind="attrs" v-on="on">
                            mdi-pencil
                        </v-icon>
                        </template>
                        {{ $t('edit_current_vehicle') }}
                    </v-tooltip>
                    </template>
                </v-text-field>
                <v-text-field
                    v-model="temp_edit_registration_no"
                    v-if="isEditModeForRegistration"
                    class="to-upper"
                    v-on:keydown="handleInput($event,'alphanumdashdot',15);"
                    :hide-details="model_view ? true: false"
                >
                    <template v-if="registration_no && typeof registration_no == 'object' && registration_no.id" v-slot:prepend>
                    <div v-if="isEditModeForRegistration">
                        <v-tooltip top z-index="9999">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon @click="saveRegistrationFromEdit()" class="ma-0 mr-1" color="primary" v-bind="attrs" v-on="on">
                            mdi-content-save
                            </v-icon>
                        </template>
                        {{ $t('save_current_vehicle') }}
                        </v-tooltip>
                    </div>
                    </template>
                </v-text-field>
            </v-col>
            <!-- Brand -->
            <v-col class="pt-0 pb-0" cols="12" :sm="model_view ? 4 : ''">
                <v-autocomplete
                    :autocomplete="autocompleteValueLocal"
                    v-model="brand"
                    :items="brands"
                    ref="brand"
                    :search-input.sync="searchBrand"
                    item-text="name"
                    item-value="id"
                    class="to-upper"
                    :label="$t('billings.brand')"
                    :error-messages="$store.state.validation && !$v.brand.maxLength ? [this.$t('billings.text_messages.brand_max_length')] : [] "
                    v-on:keydown="handleInput($event,'justalphanumeric',25);"
                    v-on:keydown.enter.prevent=""
                    @keyup="$emit('update:comp_brand', brand);"
                    @change="$emit('update:comp_brand', brand);"
                    :outlined="is_boxes"
                    :dense="is_boxes"
                    hide-no-data
                    hide-details
                ></v-autocomplete>
            </v-col>
            <!-- Model -->
            <v-col class="pt-0 pb-0" cols="12" :sm="model_view ? 4 : ''">
                <v-text-field
                    :autocomplete="autocompleteValueLocal"
                    v-model="model"
                    :error-messages="$store.state.validation && !$v.model.maxLength ? [this.$t('billings.text_messages.model_max_length')] : [] "
                    class="to-upper"
                    :label="$t('billings.model')"
                    v-on:keydown.enter.prevent=""
                    v-on:keydown="handleInput($event,'alphanumeric',80);"
                    @keyup="$emit('update:comp_model', model);"
                    :outlined="is_boxes"
                    :dense="is_boxes"
                    hide-details
                ></v-text-field>
            </v-col>
            <!-- KM -->
            <v-col class="pt-0 pb-0" cols="12" :sm="model_view ? 4 : ''" :class="model_view ? 'pt-3' : ''">
                <v-text-field
                    :autocomplete="autocompleteValueLocal"
                    v-model="km"
                    :error-messages="$store.state.validation && !$v.km.maxLength ? [this.$t('billings.text_messages.km_max_length')] : [] "
                    class="to-upper"
                    :hint="hintKmComputed"
                    :label="$t('billings.km')"
                    persistent-hint
                    v-on:keydown.enter.prevent=""
                    v-on:keydown="handleInput($event,'float',15);"
                    @keyup="$emit('update:comp_km', km);"
                    :outlined="is_boxes"
                    :dense="is_boxes"
                ></v-text-field>
            </v-col>
            <!-- VIN -->
            <v-col class="pt-0 pb-0" cols="12" :sm="model_view ? 4 : ''" :class="model_view ? 'pt-3' : ''">
                <v-text-field
                    :autocomplete="autocompleteValueLocal"
                    v-model="vin"
                    class="to-upper"
                    :label="$t('billings.vin')"
                    v-on:keydown.enter.prevent=""
                    @keyup="$emit('update:comp_vin', vin);"
                    :error-messages="$store.state.validation && !$v.vin.maxLength ? [this.$t('billings.text_messages.vin_max_length')] : [] "
                    v-on:keydown="handleInput($event,'alphanumdash',17);"
                    :outlined="is_boxes"
                    :dense="is_boxes"
                    hide-details
                ></v-text-field>
            </v-col>
            <!-- Vehicle Type -->
            <v-col class="pt-0 pb-0" cols="12" :sm="model_view ? 4 : ''" :class="model_view ? 'pt-3' : ''">
                <v-combobox
                    :autocomplete="autocompleteValueLocal"
                    v-model="vehicle_type"
                    :items="vehicle_types"
                    item-text="text"
                    item-value="id"
                    class="to-upper"
                    :label="$t('billings.vehicle_type')"
                    v-on:keydown="handleInput($event,'alphanumdash',15);"
                    v-on:keydown.enter.prevent=""
                    :outlined="is_boxes"
                    :dense="is_boxes"
                    hide-details
                ></v-combobox>
            </v-col>
            <!-- Creditors -->
            <v-col class="pt-0 pb-0" v-if="! isCompanyLeaser" cols="12" :sm="model_view ? 6 : ''" :class="model_view ? 'pt-3' : ''">
                <v-combobox
                    :autocomplete="autocompleteValueLocal"
                    v-model="creditor"
                    :items="creditors"
                    item-text="name"
                    item-value="id"
                    :label="$t('billings.creditors')"
                    v-on:keydown.enter.prevent=""
                    v-on:keydown="handleInput($event,'justalphanumeric',35);"
                    @change="$emit('update:comp_creditor', creditor); updateIsLeasing()"
                    clearable
                    :outlined="is_boxes"
                    :dense="is_boxes"
                    class="to-upper"
                    hide-no-data
                    hide-details
                >
                    <template v-slot:prepend-inner>
                    <div v-if="is_leasing && creditor">
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="black"> mdi-car-multiple</v-icon>
                        </template>
                        <span> {{ $t('billings.leasing') }} </span>
                        </v-tooltip>
                    </div>
                    <div v-if="! is_leasing && creditor">
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="black"> mdi-account-cash-outline</v-icon>
                        </template>
                        <span> {{ $t('billings.not_leasing') }} </span>
                        </v-tooltip>
                    </div>
                    </template>
                </v-combobox>
            </v-col>
            <!-- Driver -->
            <v-col class="mt-3 pt-0 pb-0" v-if="! isCompanyLeaser" cols="12" :sm="model_view ? 6 : ''" :class="model_view ? 'pt-3' : ''">
                <ClientDriverInputBox
                    :disabled="!registration_no"
                    :data.sync="driver"
                    :member.sync="second_driver"
                    :type="'driver'"
                    :showMembers="true"
                    :membersEnabled="true"
                    :showPopUpToAdd="true"
                ></ClientDriverInputBox>
            </v-col>
            <!-- Attachments -->
            <!-- <template v-if="refreshDynamicContent">
                <v-col class="mt-2 pt-0 pb-0" style="text-align: end; max-width: 80px;">
                    <v-btn small :disabled="!registration_no || (registration_no && typeof registration_no != 'object')"
                        id="attachments" class="ma-2 text-white-color" color="success" @click="showAttachmentDialog()">
                    {{
                        $store.state.attachmentCounts['vehicles'] && registration_no ? $store.state.attachmentCounts['vehicles'] : 0
                    }}
                    <v-icon dark right> mdi-paperclip</v-icon>
                    </v-btn>
                </v-col>
                <attachment-dialog
                    :showDialog.sync="attachmentDialog"
                    :data="registration_no && typeof registration_no == 'object' ? registration_no.id : null"
                    :countKey="'vehicles'"
                    :resource="'vehicles'"
                    :refresh.sync="refreshAttachment"
                ></attachment-dialog>
            </template> -->

            <confirmation-model
                :showDialog.sync="confirmationDialog"
                :openedForOperation.sync="confirmationDialogOperation"
                :text.sync="confirmationDialogConfirmationText"
                :trueText.sync="confirmationDialogTrueText"
                :falseText.sync="confirmationDialogFalseText"
            ></confirmation-model>
            <edit-vehicle-modal
                v-if="registration_no && typeof registration_no == 'object' && registration_no.id"
                :brands.sync="brands"
                :creditors.sync="creditors"
                :vehicle-types="vehicle_types"
                v-model="vehicle"
                @updated="(vehicle) => updateVehicleData(vehicle)"
                @saved="(vehicle) => handleUpdatedVehicle(vehicle)"
            />
        </v-row>

        <create-edit-driver
            ref="drivercomp"
            v-if="isCompanyLeaser"
            :comp_driver.sync="driver"
            :comp_driver_member.sync="second_driver"
            :parentData="companyLeasingCompany"
        ></create-edit-driver>
    </div>
</template>
<script>
import axios from "@/plugins/axios";
import _ from 'lodash';
import {API_BASE_URL, VIEW_MODE} from "@/config";
import {validationMixin} from 'vuelidate'
import {FAMILY_ARTICLE_CARS} from "@/config";
import AttachmentDialog from "@/components/AttachmentDialog";
import {
  required,
  email,
  requiredIf,
  phoneNum,
  numeric,
  minLength,
  maxLength,
  alpha,
  minValue,
  maxValue
} from 'vuelidate/lib/validators'
import MemberSelectDialog from "@/components/MemberSelectDialog.vue";
import ClientDriverInputBox from "../../components/ClientDriverInputBox.vue";
import EditVehicleModal from "@/components/Vehicles/EditVehicleModal.vue";
import ConfirmationModel from "@/components/ConfirmationModel.vue";
import CreateEditDriver from '../Driver/CreateEditDriver.vue';
import ShowEditVehicleModal from "@/components/Vehicles/ShowEditVehicleModal.vue";

export default {
  components: {
    ConfirmationModel,
    EditVehicleModal,
    // "attachment-dialog": AttachmentDialog,
    ClientDriverInputBox,
    CreateEditDriver,
    ShowEditVehicleModal
  },
  mixins: [validationMixin],
  validations: {
    // Vehicle Validation
    model: {
      maxLength: maxLength(80)
    },
    registration_no: {
      maxLength(value) {
        if (typeof value == 'string' && value.length > 15) {
          return false;
        }
        return true;
      },
      required: requiredIf(function () {
        return this.brand || this.model || this.vin || (this.km && parseFloat(this.km) >= 1) || this.creditor;
      }),
    },
    brand: {
      maxLength: maxLength(25)
    },
    vin: {
      maxLength: maxLength(17)
    },
    km: {
      maxLength: maxLength(10)
    }
  },
  props: {
    comp_selected_bill: [String, Object],
    comp_selected_appointment: [String, Object],
    comp_vehicle_type: [String, Object],
    comp_registration_no: [String, Object],
    comp_brand: [String, Object, Number],
    comp_model: [String, Object, Number],
    comp_km: [String, Object, Number],
    comp_reg_date: {type: String, default: new Date().toISOString().substr(0, 10)},
    comp_creditor: [String, Object, Number],
    comp_creditors: [Array],
    comp_vin: [String, Number],
    comp_is_leasing: [Boolean, Number],
    comp_driver: [String, Object, Number],
    comp_second_driver: [String, Object, Number],
    comp_client_detail: [String, Object],
    comp_addedRegistrationNumber: [Boolean],
    comp_available_depots: [Array],
    model_view: {
        type: Boolean,
        default: false
    }
  },
  data() {
    return {
      isFirstTimeSelectingForEditMode: true,
      isLoadingSearchRegistrationNumber: false,
      unknownRegistrationNumber: false,
      refreshAttachment: false,
      is_boxes: this.getCompanyPropertyFromLocalStorage('boxes_on_fields', 1),
      refreshDynamicContent: true,
      autocompleteValueLocal: 'off',
      vehicle_types: [],
      vehicle_type: this.comp_vehicle_type,
      registration_no: this.comp_registration_no,
      client_detail: this.comp_client_detail,
      client_vehicles: this.comp_client_detail ? this.comp_client_detail.vehicles : [],
      brands: [],
      brand: this.comp_brand,
      model: this.comp_model,
      km: this.comp_km,
      label_km: null,
      driver: this.comp_driver,
      second_driver: this.comp_second_driver,
      reg_date: this.comp_reg_date,
      menu_reg_date: false,
      creditors: this.comp_creditors,
      creditor: this.comp_creditor,
      search_creditor: '',
      isLoadingCreditor: false,
      vin: this.comp_vin,
      is_leasing: this.comp_is_leasing,
      token: this.$store.state.AccessToken,
      nowDate: new Date().toISOString().slice(0, 10),
      searchBrand: null,
      attachmentDialog: false,
      tempRegistrationNumber: null,
      searchRegistrationNumber: "",
      isEditModeForRegistration: false,
      temp_edit_registration_no: null,
      showMemberDialogForDriver: false,
      pauseSelectingOfItemForWhile: false,

      confirmationDialog: false,
      confirmationDialogOperation: "",
      confirmationDialogConfirmationText: "",
      confirmationDialogTrueText: "",
      confirmationDialogFalseText: "",
      driverFromChildComponent: null,
      secondDriverFromChildComponent: null
    }
  },
  mounted() {
    this.autocompleteValueLocal = this.autocompleteValue();
    this.header = {Authorization: 'Bearer ' + this.token};
    this.getBrands();
    this.getVehicleTypes();
    this.$store.commit("setSearchClientValue", null);
  },
  activated() {
    this.setDefaultVehicleType();
    this.isEditModeForRegistration = false;
    this.temp_edit_registration_no = null;
    this.searchRegistrationNumber = "";
    this.registration_no = null;
    this.client_vehicles = [];
    this.driver = null;
    this.second_driver = null;
    this.label_km = null;
    if(this.comp_client_detail && this.comp_client_detail.vehicles && this.comp_client_detail.length > 0){
        let firstRegistrationNumberForEditMoode = this.comp_client_detail[0];
        if (typeof firstRegistrationNumberForEditMoode == 'object' && 'brand' in firstRegistrationNumberForEditMoode) { // do not set if no vehicle is saved by default as it will result in in consistent work of blur action of combobox
            this.registration_no = firstRegistrationNumberForEditMoode;
        }
    }
  },
  methods: {
    
    setRegistrationNumberFromBillAndAppointment(val){
        setTimeout(() => {
            // this.registration_no = val.vehicle;
            if(this.client_detail && this.client_detail.vehicles && this.client_detail.vehicles.length > 0){
                this.client_detail.vehicles.forEach(element => {
                    console.log("selected_client",this.comp_selected_bill, val.vehicle.registration_number, element.registration_number);
                    if(element.registration_number && val.vehicle.registration_number && element.registration_number == val.vehicle.registration_number){
                        this.registration_no = element;
                        if(val.current_km && val.vehicle.km){
                            this.km = val.current_km;
                        }
                    }
                });
            }
        }, 60)
    },
    fetchClientVehicleSuggesListDebounced() {
        if(this.searchRegistrationNumber){
            this.client_vehicles = [];
            this.isLoadingSearchRegistrationNumber = true;
        }
        else{
            this.isLoadingSearchRegistrationNumber = false;
        }
        clearTimeout(this._searchTimerId);
        this._searchTimerId = setTimeout(() => {
            this.fetchClientVehicleSuggesList();
        }, 100); /* 500ms throttle */
    },
    fetchClientVehicleSuggesList() {
        if (!this.searchRegistrationNumber || typeof this.searchRegistrationNumber == "object") {
            return;
        }
        axios
            .get(
                API_BASE_URL +
                "/client_vehicles?page=1&items_per_page=20" +
                (this.comp_client_detail ? ( '&client_id=' + (typeof this.comp_client_detail == 'object' ?  this.comp_client_detail.id : this.comp_client_detail )) :  "") +
                (this.searchRegistrationNumber ? ( '&search=' +  encodeURIComponent(this.searchRegistrationNumber) ) :  "")
                ,
                {headers: this.header}
            )
            .then(({data}) => {
                this.client_vehicles = data.data;
            })
            .catch((err) => {
                console.log(err);
                this.isLoadingSearchRegistrationNumber = false;
            })
            .finally(() => (this.isLoadingSearchRegistrationNumber = false));
    },
    showPopUpForNotAllowedToEdit() {
      this.confirmationDialogConfirmationText = this.$t("vehicles.text_messages.you_cannot_change_the_vehicle_if_depot_already_exists");
      this.confirmationDialogFalseText = null;
      this.confirmationDialogTrueText = this.$t("ok");
      this.confirmationDialogOperation = "warning";
      this.confirmationDialog = true;
    },
    updateIsLeasing() {
      this.creditors.find((element) => {
        if (this.creditor && element.id == this.creditor.id) {
          this.is_leasing = !!element.is_leaser;
        }
      })
    },
    enterRegistrationFromEdit() {
      if (this.registrationNumberIsUnknown) {
        this.temp_edit_registration_no = ''
      } else {
        this.temp_edit_registration_no = this.registration_no.registration_number?.toUpperCase();
      }
      this.isEditModeForRegistration = true;
    },
    selectRegistrationNumber(item) {
      this.pauseSelectingOfItemForWhile = true;
      this.$emit('update:comp_registration_no', item);
      setTimeout(() => {
        this.registration_no = item;
        this.pauseSelectingOfItemForWhile = false;
      }, 200)
    },
    saveRegistrationFromEdit() {
      this.registration_no.registration_number = this.temp_edit_registration_no?.toUpperCase();
      this.isEditModeForRegistration = false
    },
    createNewRegistration() {
      if (!this.client_vehicles) {
        this.client_vehicles = [];
      }
      if (this.isNewItem && !this.pauseSelectingOfItemForWhile) {
        this.pauseSelectingOfItemForWhile = true;
        this.client_vehicles.push({registration_number: this.searchRegistrationNumber?.toUpperCase()})
        this.$nextTick(() => {
          this.registration_no = this.client_vehicles[this.client_vehicles.length - 1];
          this.pauseSelectingOfItemForWhile = false;
        })
      }
      this.$refs['brand'].focus();
      this.$refs['registration_number'].blur()
    },
    setRegistrationNo(e) {
      if (!e) {
        this.registration_no = null
      } else if (typeof e === 'string') {
        this.registration_no = e
      } else if (typeof e === 'object') {
        this.registration_no = e.srcElement.value
      }
    },
    editClientVehicles() {
      if (typeof this.registration_no == 'object') {
        this.$store.commit("seteditId", this.registration_no.id);
        this.$router.push('/vehicles/edit').catch(() => {
        });
      }
    },
    clearClientVehicles() {
      this.tempRegistrationNumber = null;
      this.registration_no = null;
      this.vehicle_type = null;
      this.brand = null;
      this.model = null;
      this.creditor = null;
      this.vin = null;
      this.km = null;
      this.label_km = null;
      this.is_leasing = null;
      this.driver = null;
      this.second_driver = null;
      this.setDefaultVehicleType();
    },
    showAttachmentDialog() {
      this.attachmentDialog = true;
    },
    setDefaultVehicleType() {
      if (this.vehicle_types.length > 0) {
        this.vehicle_types.forEach((element) => {
          if (element.key == 'sedan') {
            this.vehicle_type = element;
          }
        });
      }
    },
    fetchCreditorSuggListDebounced() {
      if (!this.search_creditor || typeof this.search_creditor == "object") {
        return;
      }
      axios.get(API_BASE_URL + '/creditor?' + (this.search_creditor ? ('&search=' + encodeURIComponent(this.search_creditor)) : ''), {headers: this.header})
          .then(({data}) => {
            this.creditors = data.data;
          })
          .catch((err) => {
            console.log(err);
            this.isLoadingCreditor = false;
          })
          .finally(() => (
              this.isLoadingCreditor = false
          ))
    },
    getBrands() {
      if (FAMILY_ARTICLE_CARS) {
        if (this.cachedData['brands'][FAMILY_ARTICLE_CARS] && this.cachedData['brands'][FAMILY_ARTICLE_CARS].length > 0) {
          this.brands = this.cachedData['brands'][FAMILY_ARTICLE_CARS];
        } else {
          
          axios.get(API_BASE_URL + '/brands?family=' + FAMILY_ARTICLE_CARS + '&items_per_page=-1', {headers: this.header})
              .then(({data}) => {
                this.brands = data.data;
                this.cachedData['brands'][FAMILY_ARTICLE_CARS] = this.brands;
              })
              .catch(function (error) {
                console.log('an error occured ' + error);
              })
              .finally(() => {
                
              });
        }
      }
    },
    getVehicleTypes() {
      if (this.cachedData['vehicle_types'] && this.cachedData['vehicle_types'].length > 0) {
        this.vehicle_types = this.cachedData['vehicle_types'];
      } else {
        this.getCodes('vehicle_types', 'vehicles.types')
            .then((data) => {
              this.vehicle_types = data
              this.cachedData['vehicle_types'] = this.vehicle_types;
            });
      }
    },
    updateVehicleData(vehicle) {
      this.brand = vehicle.brand_id
      this.model = vehicle.model
      this.vin = vehicle.vin
      this.km = vehicle.km
      if (this.isset(vehicle.vehicle_type_id)) {
        this.vehicle_type = this.vehicle_types.find(t => t.id === vehicle.vehicle_type_id)
      }
      if (this.isset(vehicle.creditor_id)) {
        this.creditor = this.creditors.find(c => c.id === vehicle.creditor_id)
      }


    },
    handleUpdatedVehicle(vehicle) {
      this.registration_no.km = vehicle.km;
      this.registration_no.brand_id = vehicle.brand_id;
      this.registration_no.model = vehicle.model;
      this.registration_no.vin = vehicle.vin;
      this.registration_no.vehicle_type_id = vehicle.vehicle_type_id;
      this.registration_no.creditor_id = vehicle.creditor_id;
      this.registration_no.power = vehicle.power;
      this.registration_no.contract_number = vehicle.contract_number;
      this.registration_no.fuel_id = vehicle.fuel_id;
      this.registration_no.transmission_id = vehicle.transmission_id;
      this.registration_no.gearbox_id = vehicle.gearbox_id;
      this.registration_no.cylinder_capacity = vehicle.cylinder_capacity;
      this.registration_no.contract_end_date = vehicle.contract_end_date;
      this.registration_no.registration_date = vehicle.registration_date;
    }
  },
  computed: {
    hintKmComputed(){
        if(this.label_km){
            return this.$t('billings.current_km') + ' (' +   this.label_km +')'
        }
        return '';
    },
    isCompanyLeaser(){
        return this.client_detail ? (typeof this.client_detail == 'object' ? !! this.client_detail.is_leaser : false) : false;
    },
    companyLeasingCompany(){
        return this.client_detail ? (typeof this.client_detail == 'object' ?  (this.client_detail.is_leaser ? this.client_detail : null) : null) : null;
    },
    getLastSearchedClient(){
        return this.$store.state.searchClientValue;
    },
    registrationNumberIsUnknown() {

      if (this.isEditModeForRegistration) {
        return false;
      }

      if (this.unknownRegistrationNumber) {
        return true;
      }

      let registrationNumber = this.registration_no

      if (!registrationNumber) {
        return false;
      }

      if ((typeof registrationNumber) === "object") {
        return registrationNumber.registration_number.includes('#UNKNOWN')
      }

      if ((typeof registrationNumber) === "string") {
        return registrationNumber.includes('#UNKNOWN')
      }

      return false;

    },
    registrationNumberErrorMessages() {
      // if(this.isset(this.registration_no?.id) && this.registration_no.registration_number.includes('UNKNOWN')){
      //   return [this.$t("billings.unknown_reg_no_alert")];
      // }

      return this.$store.state.validation ? this.returnErrorMessage(
          [!this.$v.registration_no.maxLength, this.$t('billings.text_messages.registration_number_max_length')],
          [!this.$v.registration_no.required, this.$t('billings.text_messages.registration_number_required_if_other_details_filled_up')]
      ) : []
    },
    vehicle() {
      return {
        completeData: this.comp_registration_no,
        id: this.comp_registration_no?.id || null,
        brand_id: this.brand?.id ?? this.brand,
        model: this.model,
        km: this.km,
        // reg_date: this.comp_reg_date,
        // menu_reg_date: false,
        creditors: this.comp_creditors,
        creditor_id: this.creditor?.id ?? this.creditor,
        vin: this.vin,
        is_leasing: this.comp_is_leasing,
        vehicle_type_id: this.vehicle_type?.id ?? this.vehicle_type,
        registration_number: this.comp_registration_no?.registration_number || this.comp_registration_no,
      }
    },
    isEditingAllowed() {
      return this.$store.state.isEditingAllowed;
    },
    isNewItem() {
      if (this.client_vehicles) {
        const data = this.client_vehicles.filter(item => item.registration_no?.toLowerCase() === this.searchRegistrationNumber?.toLowerCase())
        return data.length === 0 ? true : false;
      } else {
        return true;
      }
    },
  },
  watch: {
    comp_selected_bill(val){
        if(val && val.vehicle){
            this.setRegistrationNumberFromBillAndAppointment(val);
        }
    },
    comp_selected_appointment(val){
        if(val && val.vehicle && ! this.comp_selected_bill){
            this.setRegistrationNumberFromBillAndAppointment(val);
        }
    },
    searchRegistrationNumber(){
        this.fetchClientVehicleSuggesListDebounced();
    },
    unknownRegistrationNumber(val) {
      if (val) {
        this.searchRegistrationNumber = '#UNKNOWN';
        this.createNewRegistration();
      } else {
        this.registration_no = null;
      }
    },
    refreshAttachment() {
      console.log("refresh attachment from edit vehicle", this.refreshAttachment);
    },
    vehicle_types() {
      this.setDefaultVehicleType();
    },
    vehicle_type(val) {
      if (this.comp_registration_no == null && this.comp_brand == null && this.comp_client_detail == null) {
        this.setDefaultVehicleType();
      }
      this.$emit('update:comp_vehicle_type', val);
    },
    comp_vehicle_type: {
      handler: function () {
        this.vehicle_type = this.comp_vehicle_type;
      }
    },
    comp_registration_no: {
      handler: function () {
        if (!this.pauseSelectingOfItemForWhile) {
          this.registration_no = this.comp_registration_no;
        }
      }
    },
    comp_addedRegistrationNumber: {
      handler: function (val) {
        if (val == true) {
          console.log("added_registration_number", this.comp_addedRegistrationNumber, this.client_vehicles);
          if (this.client_vehicles == null) {
            this.client_vehicles = [this.comp_registration_no];
          } else {
            let alredayExistsIndex = -1;
            this.client_vehicles.forEach((element, index) => {
              if (typeof this.comp_registration_no == 'object' && this.comp_registration_no.registration_number == element.registration_number) {
                console.log("matched registration number---", this.comp_registration_no, element);
                alredayExistsIndex = index;
              }
            });
            if (alredayExistsIndex >= 0) {
              this.pauseSelectingOfItemForWhile = true;
              this.client_vehicles.splice(alredayExistsIndex, 1);
              this.client_vehicles.push(this.comp_registration_no);
              this.$nextTick(() => {
                this.selectRegistrationNumber(this.client_vehicles[this.client_vehicles.length - 1]);
                console.log("matched registration number---*******", this.registration_no, this.comp_registration_no, this.client_vehicles[this.client_vehicles.length - 1]);
              })
            }
          }
        }
        this.$emit('update:comp_addedRegistrationNumber', false);
      }
    },
    comp_brand: {
      handler: function () {
        this.brand = this.comp_brand;
      }
    },
    comp_model: {
      handler: function () {
        this.model = this.comp_model;
      }
    },
    comp_km: {
      handler: function () {
        this.km = this.comp_km;
      }
    },
    comp_driver: {
      handler: function () {
        this.driver = this.comp_driver;
      }
    },
    comp_second_driver: {
      handler: function () {
        this.second_driver = this.comp_second_driver
      }
    },
    comp_creditor: {
      handler: function () {
        this.creditor = this.comp_creditor;
        this.updateIsLeasing();
      }
    },
    comp_creditors: {
      handler: function () {
        this.creditors = this.comp_creditors;
      }
    },
    comp_vin: {
      handler: function () {
        this.vin = this.comp_vin;
      }
    },
    comp_is_leasing: {
      handler: function () {
        this.is_leasing = this.comp_is_leasing;
      }
    },
    comp_reg_date: {
      handler: function () {
        this.reg_date = this.comp_reg_date;
      }
    },

    comp_client_detail: {
      handler: function () {
        this.client_detail = this.comp_client_detail;
        this.client_vehicles = this.comp_client_detail ? this.comp_client_detail.vehicles : [];
        this.vehicle_type = null;
        this.registration_no = null;
        this.brand = null;
        this.model = null;
        this.reg_date = null;
        this.creditor = null;
        this.vin = null;
        this.km = null;
        this.label_km = null;
        this.driver = null;
        this.second_driver = null;
        this.is_leasing = false;
        this.unknownRegistrationNumber = false;
      }
    },

    search_creditor(val) {
      if (!val || this.creditor == val) {
        return
      }
      this.isLoadingCreditor = true;

      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        this.fetchCreditorSuggListDebounced()
      }, 200);
    },
    client_vehicles(val) {
        // this.registration_no = null;
        this.vehicle_type = null;
        this.brand = null;
        this.model = null;
        this.creditor = null;
        this.vin = null;
        this.km = null;
        this.label_km = null;
        this.is_leasing = null;
        this.setDefaultVehicleType();
        //   if(this.getLastSearchedClient.toLowerCase == ){

        //   }
        // If we searched in the client input the vehicle registration number then we will matche the searched item in vehicles and will set it to the vehicles
        if(val && val.length > 0){
            val.forEach((element) => {
                if(element.registration_number && element.registration_number?.toLowerCase() == this.getLastSearchedClient?.toLowerCase()){
                    console.log("matching resgistation number", element.registration_number, this.getLastSearchedClient);
                    this.registration_no = element;
                }
            })
        }
        this.$store.commit("setSearchClientValue", null);
        
        // if (val && val.length > 0 && !this.pauseSelectingOfItemForWhile && val.length == 1 ) { // Only select first registration number if their is only one vehicle their, other wise they will search by typing 
        //     this.isFirstTimeSelectingForEditMode = false;
        //     if (typeof val[0] == 'object' && 'brand' in val[0]) { // do not set if no vehicle is saved by default as it will result in in consistent work of blur action of combobox
        //         this.registration_no = val[0];
        //     }
        // }
    },
    registration_no(val) {

      this.refreshDynamicContent = false;
      this.refreshAttachment = true;
      if (val) {
        console.log("Registration Number", val.vehicle_type);
        if (typeof val == 'object') {
          this.tempRegistrationNumber = val;


          // if(this.isset(this.registration_no?.id) && this.registration_no.registration_number.includes('#UNKNOWN')){
          //   this.unknownRegistrationNumber = true
          // }

          // this means we have data for that registration number
          // this.vehicle_type = val.vehicle_type ? (typeof val.vehicle_type == "object" ? val.vehicle_type.id : val.vehicle_type) : null;
          if (val.vehicle_type) {
            this.vehicle_types.forEach(element => {
              if (element.id == val.vehicle_type.id) {
                this.vehicle_type = element;
              }
            });
          } else {
            this.vehicle_type = null;
          }
          this.brand = val.brand;
          this.model = val.model;
          this.creditor = null;
          this.vin = val.vin;
            // NOTE: Here we are not putting value of km as its a feature. When we create bill then we don't have to show the km from vehicle, but we show it from billing
                // this.km = val.km;
                this.label_km = val.km;
          this.is_leasing = val.is_leasing;
          this.creditor = val.creditor;
          this.reg_date = val.registration_date;
          this.driver = val.driver;
          this.second_driver = val.second_driver;
        } else {
          // no operation need to perform
          // if(typeof this.registration_no === 'string' && this.registration_no.includes('#UNKNOWN')){
          //   this.unknownRegistrationNumber = true
          // }

          if (val == null || val == "") {
            this.tempRegistrationNumber = null;
          } else {
            this.$emit('update:comp_registration_no', val);
            console.log("updating registration number-----------------", val);
            this.$nextTick(() => {
              this.clearClientVehicles();
              this.registration_no = val;
              console.log("updatinged registration number", this.registration_no);
            })
          }
        }

      } else {
        this.tempRegistrationNumber = null;
        this.unknownRegistrationNumber = false;
        this.clearClientVehicles();
      }
      if (typeof val == 'object' && val && val.id) {
        this.$emit('update:comp_registration_no', val);
      } else if (typeof val == 'object' && val && !val.id) {
        this.$emit('update:comp_registration_no', val.registration_number);
      } else {
        this.$emit('update:comp_registration_no', val);
      }

      this.$nextTick(() => {
        this.refreshDynamicContent = true;
      });
    },
    brand(val) {
      this.$emit('update:comp_brand', val);
    },
    model(val) {
      this.$emit('update:comp_model', val);
    },
    km(val) {
      this.$emit('update:comp_km', val);
    },
    driver(val) {
      this.$emit('update:comp_driver', val);
    },
    second_driver(val) {
      this.$emit('update:comp_second_driver', val);
    },
    driverFromChildComponent(val) {
      this.$emit('update:comp_driver', val);
    },
    secondDriverFromChildComponent(val) {
      this.$emit('update:comp_second_driver', val);
    },
    creditor(val) {
      if (val) {
        this.creditors.find((creditor) => {
          if (creditor.id == val) {
            this.is_leasing = !!creditor.is_leaser;
          }
        })

      } else {
        this.is_leasing = false;
      }
      this.$emit('update:comp_creditor', val);
    },
    vin(val) {
      this.$emit('update:comp_vin', val);
    },
    is_leasing(val) {
      this.$emit('update:comp_is_leasing', val);
    },
    reg_date(val) {
      this.$emit('update:comp_reg_date', val);
    }
  }

};
</script>