var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"relative","float":"right","top":"-30%","visibility":"hidden"},attrs:{"id":'editArticle' + _vm.item.srno}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-1",class:'mdi mdi-pencil',attrs:{"color":"primary"},on:{"click":function($event){return _vm.editItem(
                        _vm.item
                    )}}},'v-icon',attrs,false),on))]}}])},[_vm._v(" "+_vm._s(_vm.$t('icon_group.edit_article'))+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-1",class:_vm.item.text
                        ? 'mdi mdi-note-edit-outline'
                        : 'mdi mdi-note-edit',attrs:{"color":"warning"},on:{"click":function($event){return _vm.addTextAndGroupToArticle(
                        _vm.item
                    )}}},'v-icon',attrs,false),on))]}}])},[_vm._v(" "+_vm._s(_vm.$t('icon_group.edit_note_article'))+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-1",class:'mdi mdi-content-copy',attrs:{"color":"success"},on:{"click":function($event){return _vm.copyEan(
                        _vm.item
                    )}}},'v-icon',attrs,false),on))]}}])},[_vm._v(" "+_vm._s(_vm.$t('icon_group.copy_ean'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }