<template>
  <v-btn :small="small" color="primary">
    {{ label || $t("nav_buttons.add") }}
    <v-icon dark right> mdi-plus </v-icon>
  </v-btn>
</template>
<script>
export default{
  name: 'AddButton',
  props: ['small', 'label'],

}
</script>