<template>
    <div>
        <v-card>
            <v-tabs v-model="tab" background-color="primary" dark>
                <v-tab key="reserves">
                    {{ $t("stocks.label_reserve") }}
                </v-tab>
                <v-tab key="supplier">
                    {{ $t("scheduler.supplier") }}
                    {{ $t("vehicles.vehicle_tab.orders") }}
                </v-tab>
                <v-tab key="reception_history">
                    {{ $t("stocks.reception_history") }}
                </v-tab>
                <v-tab key="latest_sales_of_article">
                    {{ $t("stocks.latest_sales_of_article") }}
                </v-tab>
                <v-tab key="dots">
                    {{ $t("stocks.dots") }}
                    <v-icon
                        class="ml-2"
                        v-if="dots.length > 0 && pendingReceivedQuantity() !== 0"
                        color="red"
                        >mdi-alert</v-icon
                    >
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <!-- Reserves -->
                <v-tab-item :key="'reserves'">
                    <v-card flat>
                        <v-data-table
                            :loading="loading"
                            :headers="reservedStocksHeaders"
                            :items="reservedStockTableItems"
                            class="elevation-1"
                            :items-per-page="reservedStockTableItems.length"
                            :footer-props="{
                                'items-per-page-options': dataTableForReserveOptions,
                            }"
                            :options="forReserveOptions"
                            :sort-by.sync="sortByForReserve"
                            :sort-desc.sync="sortDescForReserve"
                            @update:sort-by="updateSortForReserve"
                            @update:sort-desc="updateSortOrderForReserve"
                            @update:page="updatePaginationForReserve"
                            @update:items-per-page="updateItemsPerPageForReserve"
                            height="450px"
                        >
                            <template v-slot:item="{ item }">
                                <tr :class="setRowColorClassForReserved(item)">
                                    <!-- Full Name -->
                                    <td>
                                        {{ item.full_name }}
                                    </td>
                                    <!-- Appointment Date -->
                                    <td>
                                        <span
                                            v-if="item.appointment_date"
                                            @click="
                                                navigateToAppointmentPage(
                                                    item.appointment_id
                                                )
                                            "
                                            :style="{
                                                color: 'blue',
                                                textDecoration: 'underline',
                                                cursor: 'pointer',
                                            }"
                                        >
                                            {{ item.appointment_date }}
                                        </span>
                                    </td>
                                    <!-- Quantity -->
                                    <td>
                                        {{ item.quantity }}
                                        <template v-if="item.received_quantity > 0">
                                            <span class="text-green">
                                                ({{ item.received_quantity }})
                                            </span>
                                        </template>
                                    </td>
                                    <!-- Acquisition Price -->
                                    <td>
                                        {{
                                            (item.supplier_order_acquisition_price
                                                ? item.supplier_order_acquisition_price
                                                : 0
                                            ).toFixed(getDecimalNumber())
                                        }}
                                    </td>
                                    <!-- Client Price -->
                                    <td>
                                        {{
                                            (item.order_selling_price
                                                ? item.order_selling_price
                                                : 0
                                            ).toFixed(getDecimalNumber())
                                        }}
                                    </td>
                                    <!-- Document Number  -->
                                    <td>
                                        <span
                                            @click="
                                                navigateToOrdersPage(item.order_id)
                                            "
                                            :style="{
                                                color: 'blue',
                                                textDecoration: 'underline',
                                                cursor: 'pointer',
                                            }"
                                        >
                                            {{ item.identifier }}
                                        </span>
                                    </td>
                                    <!-- Order Created At -->
                                    <td>
                                        {{ formatDate(item.order_created_at) }}
                                    </td>
                                    <!-- Orderer Name -->
                                    <td>
                                        {{ item.orderer_name }}
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-tab-item>

                <!-- Supplier Orders -->
                <v-tab-item :key="'supplier'">
                    <v-card flat>
                        <v-data-table
                            :loading="loading"
                            :headers="reservedSupplierStockHeaders"
                            :items="reservedSupplierStockTableItems"
                            class="elevation-1"
                            :items-per-page="reservedSupplierStockTableItems.length"
                            :sortable="false"
                            :footer-props="{
                                'items-per-page-options': dataTableForSupplierStockOptions,
                            }"
                            :options="forSupplierStockOptions"
                            :sort-by.sync="sortByForSupplierStock"
                            :sort-desc.sync="sortDescForSupplierStock"
                            @update:sort-by="updateSortForSupplierStock"
                            @update:sort-desc="updateSortOrderForSupplierStock"
                            @update:page="updatePaginationForSupplierStock"
                            @update:items-per-page="
                                updateItemsPerPageForSupplierStock
                            "
                            height="450px"
                            :item-class="itemRowBackground"
                            @click:row="editSupplierStockTableItem"
                        >
                            <template v-slot:item.icons="{ item }">
                                <v-tooltip bottom z-index="9999">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            v-if="item.status_id == 10091000"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            mdi-file-document-multiple
                                        </v-icon>
                                        <v-icon
                                            v-else-if="item.status_id == 10091001"
                                            v-bind="attrs"
                                            v-on="on"
                                            color="primary"
                                        >
                                            mdi-cart-check
                                        </v-icon>
                                        <v-icon
                                            v-else-if="item.status_id == 10091002"
                                            v-bind="attrs"
                                            v-on="on"
                                            color="red"
                                        >
                                            mdi-close-network
                                        </v-icon>
                                        <v-icon
                                            v-else-if="item.status_id == 10091003"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            mdi-archive-cancel
                                        </v-icon>
                                        <v-icon
                                            v-else-if="item.status_id == 10091004"
                                            v-bind="attrs"
                                            v-on="on"
                                            color="success"
                                        >
                                            mdi-archive-arrow-down</v-icon
                                        >
                                        <v-icon
                                            v-else-if="item.status_id == 10091005"
                                            v-bind="attrs"
                                            v-on="on"
                                            color="success"
                                        >
                                            mdi-archive-check
                                        </v-icon>
                                    </template>
                                    <span>
                                        {{
                                            translateKeys(
                                                getReceptionStatusById(
                                                    item.status_id
                                                ),
                                                "key",
                                                "receptions.statuses"
                                            )
                                        }}
                                    </span>
                                </v-tooltip>
                            </template>
                            <template v-slot:item.supplier="{ item }">
                                <span>
                                    {{ item.supplier.supplier_identifier }}
                                </span>
                            </template>
                            <template v-slot:item.quantity="{ item }">
                                <span>
                                    {{ item.quantity }}
                                </span>
                                /
                                <span>
                                    {{ item.confirmed_quantity }}
                                    <template v-if="item.received_quantity > 0">
                                        <span class="text-green">
                                            ({{ item.received_quantity }})
                                        </span>
                                    </template>
                                </span>
                            </template>
                            <template v-slot:item.document_number="{ item }">
                                <span
                                    @click="navigateToOrdersPage(item.order_id)"
                                    :style="{
                                        color: 'blue',
                                        cursor: 'pointer',
                                        textDecoration: 'underline',
                                    }"
                                >
                                    {{ item.identifier }}
                                </span>
                            </template>
                            <template v-slot:item.short_name="{ item }">
                                <template v-if="item.short_name">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on">{{
                                                item.short_name
                                            }}</span>
                                        </template>
                                        <span>{{ item.orderer_name }}</span>
                                    </v-tooltip>
                                </template>
                            </template>
                            <template v-slot:item.appointment_date="{ item }">
                                <span
                                    v-if="item.appointment_date"
                                    @click="
                                        navigateToAppointmentPage(
                                            item.appointment_id
                                        )
                                    "
                                    :style="{
                                        color: 'blue',
                                        cursor: 'pointer',
                                        textDecoration: 'underline',
                                    }"
                                >
                                    {{
                                        item.appointment_date
                                            ? formatDate(item.appointment_date)
                                            : ""
                                    }}
                                </span>
                            </template>
                            <template v-slot:item.ordered_on="{ item }">
                                <span>
                                    {{
                                        item.ordered_on
                                            ? formatDate(item.ordered_on)
                                            : ""
                                    }}
                                </span>
                            </template>
                            <template v-slot:item.delivery_date="{ item }">
                                <span>
                                    {{
                                        item.delivery_date
                                            ? formatDate(item.delivery_date)
                                            : ""
                                    }}
                                </span>
                            </template>
                            <template v-slot:item.orderer_name="{ item }">
                                {{ item.orderer_name }}
                            </template>
                        </v-data-table>
                    </v-card>
                </v-tab-item>

                <!-- Reception History -->
                <v-tab-item :key="'reception_history'">
                    <v-card flat>
                        <v-data-table
                            :loading="loading"
                            :headers="receptionHistoryHeaders"
                            :items="articleReceptionHistory"
                            :server-items-length="totalItemsForReceptionHistory"
                            class="elevation-1"
                            :items-per-page="articleReceptionHistory.length"
                            :sortable="false"
                            :footer-props="{
                                'items-per-page-options': dataTableForReceptionHistoryOptions,
                            }"
                            :options="forReceptionHistoryOptions"
                            :sort-by.sync="sortByForReceptionHistory"
                            :sort-desc.sync="sortDescForReceptionHistory"
                            @update:sort-by="updateSortForReceptionHistory"
                            @update:sort-desc="updateSortOrderForReceptionHistory"
                            @update:page="updatePaginationForReceptionHistory"
                            @update:items-per-page="
                                updateItemsPerPageForReceptionHistory
                            "
                            height="450px"
                        >
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td>
                                        {{
                                            item.supplier_identifier
                                                ? item.supplier_identifier
                                                : "INT"
                                        }}
                                    </td>
                                    <td>
                                        {{ item.delivery_note_reference }}
                                    </td>
                                    <td>
                                        {{ item.reception_received_quantity }}
                                    </td>
                                    <td>
                                        {{
                                            item.acquisition_price.toFixed(
                                                getDecimalNumber()
                                            )
                                        }}
                                    </td>
                                    <td>
                                        {{
                                            item.delivery_date
                                                ? formatDate(item.delivery_date)
                                                : ""
                                        }}
                                    </td>
                                    <td>
                                        {{
                                            item.reception_created_at
                                                ? formatDateTime(
                                                    item.reception_created_at
                                                )
                                                : ""
                                        }}
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-tab-item>

                <!-- Latest Sales of article -->
                <v-tab-item :key="'latest_sales_of_article'">
                    <v-card flat>
                        <v-data-table
                            :loading="loading"
                            :headers="latestArticleHeaders"
                            :items="latestArticles"
                            :server-items-length="totalItemsForLatestArticle"
                            class="elevation-1"
                            :footer-props="{
                                'items-per-page-options': dataTableForLatestArticleOptions,
                            }"
                            :options="forLatestArticleOptions"
                            :items-per-page="
                                latestArticles && latestArticles.length
                            "
                            :sort-by.sync="sortByForLatestArticle"
                            :sort-desc.sync="sortDescForLatestArticle"
                            @update:sort-by="updateSortForLatestArticle"
                            @update:sort-desc="updateSortOrderForLatestArticle"
                            @update:page="updatePaginationForLatestArticle"
                            @update:items-per-page="
                                updateItemsPerPageForLatestArticle
                            "
                            height="450px"
                        >
                            <template v-slot:item.acquisition_price="{ item }">
                                {{
                                    (item.acquisition_price
                                        ? item.acquisition_price
                                        : 0
                                    ).toFixed(getDecimalNumber())
                                }}
                            </template>

                            <template v-slot:item.full_name="{ item }">
                                {{ item.name ? item.name : "" }}
                            </template>

                            <template v-slot:item.orderer_name="{ item }">
                                {{ item.orderer_name }}
                            </template>

                            <template v-slot:item.client_price="{ item }">
                                {{
                                    (item.price ? item.price : 0).toFixed(
                                        getDecimalNumber()
                                    )
                                }}
                            </template>

                            <template v-slot:item.document_number="{ item }">
                                <span
                                    @click="navigateToOrdersPage(item.id)"
                                    :style="{
                                        color: 'blue',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                    }"
                                >
                                    {{ item.identifier }}
                                </span>
                            </template>

                            <template v-slot:item.created_at="{ item }">
                                <span>
                                    {{
                                        item.created_at
                                            ? formatDateTime(item.created_at)
                                            : ""
                                    }}
                                </span>
                            </template>
                            <template v-slot:item.quantity="{ item }">
                                {{ parseFloat(item.quantity) }}
                            </template>
                        </v-data-table>
                    </v-card>
                </v-tab-item>

                <!-- Dots of Article -->
                <v-tab-item :key="'dots'">
                    <v-card flat>
                        <v-data-table
                            :loading="loadingDot"
                            :headers="dotHeaders"
                            :items="dots"
                            :server-items-length="totalItemsForDots"
                            class="elevation-1"
                            :footer-props="{
                                'items-per-page-options': dataTableForDotOptions,
                            }"
                            :options="forDotOptions"
                            :items-per-page="dots && dots.length"
                            :sort-by.sync="sortByForDot"
                            :sort-desc.sync="sortDescForDot"
                            @update:sort-by="updateSortForDot"
                            @update:sort-desc="updateSortOrderForDot"
                            @update:page="updatePaginationForDot"
                            @update:items-per-page="updateItemsPerPageForDot"
                            height="390px"
                        >
                            <template v-slot:top>
                                <v-toolbar flat class="pt-8">
                                    <v-row>
                                        <v-col cols="12" sm="3">
                                            <v-text-field
                                                :autocomplete="
                                                    autocompleteValueLocal
                                                "
                                                class="mr-3 pb-5 to-upper"
                                                :label="
                                                    $t(
                                                        'receptions.modify_article.dot'
                                                    )
                                                "
                                                v-bind:clearable="true"
                                                clear-icon="mdi-close"
                                                v-model="dot_property"
                                                v-on:keydown.enter.prevent="
                                                    addDotToDataTable()
                                                "
                                                :disabled="
                                                    pendingReceivedQuantity() <= 0
                                                "
                                                v-on:keydown="
                                                    handleInput($event, 'int', 4)
                                                "
                                                :outlined="is_boxes"
                                                :dense="is_boxes"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <v-text-field
                                                :autocomplete="
                                                    autocompleteValueLocal
                                                "
                                                class="mr-3 to-upper"
                                                :label="
                                                    $t(
                                                        'receptions.modify_article.quantity'
                                                    )
                                                "
                                                v-bind:clearable="true"
                                                clear-icon="mdi-close"
                                                v-model="quantity"
                                                v-on:keydown.enter.prevent="
                                                    addDotToDataTable()
                                                "
                                                :disabled="
                                                    pendingReceivedQuantity() <= 0
                                                "
                                                v-on:keydown="
                                                    handleInput($event, 'int', 6)
                                                "
                                                :dense="is_boxes"
                                                :outlined="is_boxes"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="2">
                                            <v-btn
                                                color="primary"
                                                @click="addDotToDataTable()"
                                                :disabled="
                                                    pendingReceivedQuantity() -
                                                        parseInt(quantity) <
                                                        0
                                                "
                                            >
                                                {{
                                                    $t(
                                                        "receptions.modify_article.add"
                                                    )
                                                }}
                                                <v-icon dark right>
                                                    mdi-plus-thick</v-icon
                                                >
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-toolbar>
                            </template>
                            <template #item="{item, index}">
                                <tr
                                    @mouseenter="hoveredRow = item.dot_property"
                                    @mouseleave="hoveredRow = null"
                                >
                                    <td>{{ item.dot_property }}</td>
                                    <td>
                                        <span
                                            class="cursor-pointer"
                                            v-show="
                                                editDotQuantityId !==
                                                    item.dot_property
                                            "
                                            @dblclick="switchToEditInput(item)"
                                            >{{ item.quantity }}
                                            <v-icon
                                                v-if="
                                                    hoveredRow === item.dot_property
                                                "
                                                color="blue"
                                                class="ml-2"
                                                right
                                                @click="switchToEditInput(item)"
                                            >
                                                mdi-pencil</v-icon
                                            >
                                        </span>
                                        <v-text-field
                                            style="width: 30px"
                                            v-show="
                                                editDotQuantityId ===
                                                    item.dot_property
                                            "
                                            v-model="item.new_quantity"
                                            @keydown.enter="handleDotUpdate(item)"
                                            @blur="
                                                () => {
                                                    handleDotUpdate(item);
                                                    editDotQuantityId = null;
                                                }
                                            "
                                            :id="
                                                'dot_quantity_' + item.dot_property
                                            "
                                            @keydown="handleInput($event, 'int', 4)"
                                        />
                                    </td>
                                    <td>{{ item.created_at }}</td>
                                    <td>
                                        <v-icon
                                            @click="deleteDotItem(index)"
                                            class="hover-icon"
                                            color="red"
                                        >
                                            mdi-delete
                                        </v-icon>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card>

        <ModifyReceptionDetails
            v-if="showReceptionDetail"
            @change-popup-model-visibility="onChangePopupModelVisibility"
            :data="receptionDetail"
            :rec_statuses="reception_order_statuses"
        ></ModifyReceptionDetails>
    </div>
</template>
<script>
import axios from "@/plugins/axios";
import { mapState } from "vuex";
import { API_BASE_URL } from "@/config";
import moment from "moment";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import ModifyReceptionDetails from "@/components/Reception/ModifyReceptionDetails.vue";

export default {
    name: "ReservedStocksTable",
    components:{
        ModifyReceptionDetails
    },
    props: {
        articleId: {
            type: Number,
            required: true,
        },
        familyArticleId: {
            type: Number,
            required: true,
        },
        activeTab: {
            default: 0,
        },
        data: {
            type: Object,
        },
    },

    data() {
        return {
            showReceptionDetail: false,
            is_boxes: this.getCompanyPropertyFromLocalStorage(
                "boxes_on_fields",
                1
            ),
            receptionDetail: null,

            autocompleteValueLocal: "off",
            forLatestArticleOptions: {},
            reception_order_statuses: [],
            reception_status: null,
            reservedStocks: [],
            supplierReservedStocks: [],
            headers: {},
            familyArticle: null,
            latestArticles: [],
            totalItemsForLatestArticle: 0,
            sortByForLatestArticle: "id",
            sortDescForLatestArticle: false,
            dataTableForLatestArticleOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
            pageForLatestArticle: 1,
            itemsPerPageForLatestArticle: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
            latestArticleHeaders: [
                {
                    text: this.$t("scheduler.client_name"),
                    value: "full_name",
                    align: "start",
                },
                {
                    text: this.$t("billings.quantity"),
                    value: "quantity",
                    align: "start",
                },
                {
                    text: this.$t("scheduler.acquisition_price"),
                    value: "acquisition_price",
                    align: "start",
                },
                {
                    text: this.$t("stocks.client_price"),
                    value: "client_price",
                    align: "start",
                },
                {
                    text: this.$t("scheduler.order_no"),
                    value: "document_number",
                    align: "start",
                },
                {
                    text: this.$t("scheduler.created_at"),
                    value: "created_at",
                    align: "start",
                },
                {
                    text: this.$t("stocks.orderer_name"),
                    value: "orderer_name",
                    align: "start",
                },
            ],

            forDotOptions: {},
            dotHeaders: [
                {
                    text: this.$t("receptions.modify_article.dot"),
                    align: "start",
                    sortable: false,
                    value: "dot_property",
                    width: "10",
                },
                {
                    text: this.$t("receptions.modify_article.quantity"),
                    align: "start",
                    sortable: false,
                    value: "quantity",
                    width: "30",
                },
                {
                    text: this.$t("billings.created_date"),
                    align: "start",
                    sortable: false,
                    value: "created_at",
                    width: "500",
                },
                {
                    text: "",
                    align: "center",
                    sortable: false,
                    value: "actions",
                    width: "50",
                },
            ],
            dots: [],
            totalItemsForDots: 0,
            sortByForDot: "id",
            sortDescForDot: false,
            dataTableForDotOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
            pageForDot: 1,
            itemsPerPageForDot: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
            quantity: null,
            dot_property: null,

            totalItemsForSupplierStock: 0,
            sortByForSupplierStock: "id",
            sortDescForSupplierStock: false,
            dataTableForSupplierStockOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
            pageForSupplierStock: 1,
            itemsPerPageForSupplierStock: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],

            totalItemsForReserve: 0,
            sortByForReserve: "id",
            sortDescForReserve: false,
            dataTableForReserveOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
            pageForReserve: 1,
            itemsPerPageForReserve: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],

            reservedStocksHeaders: [
                {
                    text: this.$t("scheduler.client_name"),
                    value: "full_name",
                    align: "start",
                },
                {
                    text: this.$t("scheduler.appointment_date"),
                    value: "appointment_date",
                    align: "start",
                },
                {
                    text: this.$t("billings.quantity"),
                    value: "quantity",
                    align: "start",
                },
                {
                    text: this.$t("scheduler.acquisition_price"),
                    value: "acquisition_price",
                    align: "start",
                },
                {
                    text: this.$t("stocks.client_price"),
                    value: "client_price",
                    align: "start",
                },
                {
                    text: this.$t("scheduler.order_no"),
                    value: "document_number",
                    align: "start",
                },
                {
                    text: this.$t("scheduler.order_creation_date"),
                    value: "order_created_at",
                    align: "start",
                },
                {
                    text: this.$t("stocks.orderer_name"),
                    value: "orderer_name",
                    align: "start",
                },
            ],
            reservedSupplierStockHeaders: [
                {
                    text: "",
                    value: "icons",
                    width: "50px",
                    align: "start",
                    sortable: false,
                },
                {
                    text: this.$t("scheduler.supplier"),
                    value: "supplier",
                    width: "50px",
                    align: "start",
                    sortable: false,
                },
                {
                    text: this.$t("receptions.orderer"),
                    value: "short_name",
                    width: "50px",
                    align: "start",
                    sortable: false,
                },
                {
                    text: this.$t("scheduler.client_name"),
                    value: "full_name",
                    width: "200px",
                    sortable: false,
                    align: "start",
                },
                {
                    text: this.$t("billings.quantity_and_confirmed_quantity"),
                    value: "quantity",
                    // width: "10px",
                    sortable: false,
                    align: "start",
                },
                {
                    text: this.$t("scheduler.acquisition_price"),
                    value: "acquisition_price",
                    // width: "10px",
                    sortable: false,
                    align: "start",
                },
                {
                    text: this.$t("scheduler.order_no"),
                    value: "start",
                    // width: "10px",
                    sortable: false,
                    align: "center",
                },
                {
                    text: this.$t("scheduler.appointment_date"),
                    value: "appointment_date",
                    sortable: false,
                    align: "start",
                },
                {
                    text: this.$t("scheduler.ordered_on"),
                    value: "ordered_on",
                    sortable: false,
                    align: "start",
                },
                {
                    text: this.$t("scheduler.expected_delivery"),
                    value: "delivery_date",
                    sortable: false,
                    align: "start",
                },
                // {
                //     text: this.$t("receptions.status"),
                //     value: "supplier_status",
                //     sortable: false,
                //     align: "center",
                // },
                {
                    text: this.$t("stocks.orderer_name"),
                    value: "orderer_name",
                    align: "start",
                },
            ],
            articleReceptionHistory: [],
            totalItemsForReceptionHistory: 0,
            sortByForReceptionHistory: "id",
            sortDescForReceptionHistory: false,
            forReceptionHistoryOptions: {},
            dataTableForReceptionHistoryOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
            pageForReceptionHistory: 1,
            itemsPerPageForReceptionHistory: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
            forSupplierStockOptions: {},
            forReserveOptions: {},
            receptionHistoryHeaders: [
                {
                    text: this.$t("stocks.supplier"),
                    value: "supplier",
                    // width: "10px",
                    sortable: false,
                    align: "start",
                },
                {
                    text: this.$t("receptions.reference"),
                    value: "reference",
                    // width: "10px",
                    sortable: false,
                    align: "start",
                },
                {
                    text: this.$t("scheduler.received_quantity"),
                    value: "received_quantity",
                    // width: "10px",
                    sortable: false,
                    align: "start",
                },
                {
                    text: this.$t("scheduler.acquisition_price"),
                    value: "acquisition_price",
                    // width: "10px",
                    sortable: false,
                    align: "start",
                },
                // {
                //     text: this.$t("scheduler.new_price"),
                //     value: "new_price",
                //     // width: "10px",
                //     sortable: false,
                //     align: "left",
                // },
                {
                    text: this.$t("stocks.delivery_date"),
                    value: "delivery_date",
                    // width: "10px",
                    sortable: false,
                    align: "start",
                },
                {
                    text: this.$t("receptions.received_date"),
                    value: "received_date",
                    // width: "10px",
                    sortable: false,
                    align: "start",
                },
            ],
            tab: null,
            loadingDot: false,
            editDotQuantityId: null,
            hoveredRow: null,
        };
    },
    async created() {
        this.tab = this.activeTab;

        this.headers = {
            headers: {
                Authorization: "Bearer " + this.AccessToken,
            },
        };
        this.getSupplierOrderStatuses();
        await this.getReservedStocks();
        await this.getSupplierReservedStocks();
        await this.getFamilyArticle();
        await this.getArticleReceptionHistory();
        await this.getLatestSalesOfArticle();
        await this.getArticleDots();
    },
    computed: {
        ...mapState(["AccessToken"]),
        decimalPrecision() {
            return this.familyArticle
                ? this.familyArticle.quantity_decimals
                : 0;
        },
        reservedStockTableItems() {
            return this.reservedStocks
                .map((item) => {
                    return {
                        full_name:
                            (item.name ? item.name : "") +
                            " " +
                            (item.first_name ? item.first_name : ""),
                        quantity: this.fixDecimal(
                            item.quantity,
                            this.decimalPrecision
                        ),
                        received_quantity: item.received_quantity,
                        document_number: item.document_number,
                        identifier: item.identifier,
                        appointment_date: item.start_time
                            ? item.start_time
                            : null,
                        order_id: item.order_id,
                        appointment_id: item.appointment_id,
                        supplier_identifier: item.supplier_identifier,
                        supplier_name: item.supplier_name,
                        supplier_order_acquisition_price:
                            item.acquisition_price,
                        order_acquisition_price: item.order_acquisition_price,
                        order_selling_price: item.price,
                        orderer_name: item.orderer_name,
                        order_created_at: item.order_created_at,
                    };
                })
                .sort((a, b) => {
                    return b.order_id - a.order_id;
                });

            // sort data by order_id desc
        },
        reservedSupplierStockTableItems() {
            return this.supplierReservedStocks
                .map((item) => {
                    return {
                        id: item.supplier_order_id,
                        supplier: item,
                        full_name: item.document_number
                            ? (item.name ? item.name : "") +
                              " " +
                              (item.first_name ? item.first_name : "")
                            : this.$t("receptions.internal_stock"),
                        short_name: item.orderer_user_id ? item.short_name : "",
                        name: item.orderer_user_id ? item.name : "",
                        quantity: this.fixDecimal(
                            item.ordered_quantity,
                            this.decimalPrecision
                        ),
                        confirmed_quantity: this.fixDecimal(
                            item.confirmed_quantity,
                            this.decimalPrecision
                        ),
                        received_quantity: this.fixDecimal(
                            item.received_quantity,
                            this.decimalPrecision
                        ),
                        document_number: item.document_number,
                        identifier: item.identifier,
                        acquisition_price: this.fixDecimal(
                            item.acquisition_price,
                            2
                        ),
                        appointment_date: item.start_time
                            ? item.start_time
                            : null,
                        ordered_on: item.created_at ? item.created_at : "",
                        delivery_date: item.delivery_date
                            ? item.delivery_date
                            : "",
                        order_id: item.order_id,
                        appointment_id: item.appointment_id,
                        orderer_name: item.orderer_name,
                        status_id: item.status_id,
                    };
                })
                .sort((a, b) => {
                    return b.order_id - a.order_id;
                });
        },
        loading() {
            return this.$store.state.isLoading;
        },
    },
    methods: {
        async editSupplierStockTableItem(item){
            if(item.status_id == 10091005 || item.status_id == 10091004){
                return
            }
            this.receptionDetail = null;
            await axios
                .get(
                    API_BASE_URL +
                        "/receptions/orders?page=1" +
                        "&items_per_page=10" +
                        (item && item.id
                            ? "&id=" + item.id
                            : ""),
                    { headers: this.header }
                )
                .then(({ data }) => {
                    if(data.data && data.data.length > 0){
                        this.receptionDetail = data.data[0];
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.showReceptionDetail = true;
                });
        },
        onChangePopupModelVisibility(){
            this.showReceptionDetail = !this.showReceptionDetail;
            this.receptionDetail = null;
            this.getSupplierReservedStocks();
        },
        itemRowBackground(item){
            if(item && (item.status_id == 10091003 || item.status_id == 10091002)){
                return 'ourRed';
            }
            return '';
        },
        pendingReceivedQuantity() {
            if (this.data) {
                let receivedQuantityInDot = 0;
                this.dots.forEach((element) => {
                    if (element.quantity) {
                        receivedQuantityInDot += parseInt(
                            element.quantity ?? 0
                        );
                    }
                });
                return (
                    parseInt(this.data.stock ?? 0) -
                    parseInt(receivedQuantityInDot)
                );
            }
        },
        deleteDotItem(index) {
            this.dots.splice(index, 1);
            this.submitArticleDotModel();
        },
        getArticleDots() {
            this.dots = [];
            this.$store.state.isLoading = true;
            axios
                .get(
                    API_BASE_URL +
                        "/stock_property/" +
                        this.articleId +
                        "?property_class_id=dot_property",
                    {
                        headers: {
                            Authorization:
                                "Bearer " + this.$store.state.AccessToken,
                        },
                    }
                )
                .then((response) => {
                    let dotLoaded = [];
                    response.data.data.forEach((dot) => {
                        dotLoaded.push({
                            id: this.dots.length + 1,
                            dot_property: dot.property,
                            quantity: dot.counter,
                            created_at: moment(dot.created_at).format(
                                "MM.yyyy"
                            ),
                            updated_at: moment(dot.updated_at).format(
                                "MM.yyyy"
                            ),
                        });
                    });
                    this.dots = dotLoaded;
                    this.totalItemsForDots = this.dots?.length;
                })
                .catch(function(error) {
                    console.log("an error occured " + error);
                })
                .finally(() => {
                    this.$store.state.isLoading = false;
                });
        },
        addDotToDataTable() {
            if (this.dot_property.length == 4 && this.quantity > 0) {
                let found = false;
                this.dots.forEach((item) => {
                    if (
                        parseInt(item.dot_property) ==
                        parseInt(this.dot_property)
                    ) {
                        item.quantity =
                            parseInt(item.quantity) + parseInt(this.quantity);
                        found = true;
                    }
                });

                if (!found) {
                    this.dots.push({
                        id: this.dots.length + 1,
                        dot_property: this.dot_property,
                        quantity: this.quantity,
                        created_at: this.getCurrentDate(true),
                        updated_at: this.getCurrentDate(true),
                    });
                }
                this.dot_property = null;
                this.quantity = null;
            }
            this.totalItemsForDots = this.dots?.length;
            this.submitArticleDotModel();
        },
        submitArticleDotModel() {
            let data = {};
            let allDots = [];
            this.dots.forEach((dot) => {
                allDots.push({
                    property: dot.dot_property,
                    counter: dot.quantity,
                });
            });
            let items = Object.assign({}, allDots);
            data.dot_items = items;
            data.property_class_id = "dot_property";
            this.loadingDot = true;
            axios
                .put(API_BASE_URL + "/stock_property/" + this.articleId, data, {
                    headers: {
                        Authorization:
                            "Bearer " + this.$store.state.AccessToken,
                    },
                })
                .then((response) => {
                    let dotLoaded = [];
                    response.data.data.forEach((dot) => {
                        dotLoaded.push({
                            id: this.dots.length + 1,
                            dot_property: dot.property,
                            quantity: dot.counter,
                        });
                    });
                    this.dots = dotLoaded;
                    this.totalItemsForDots = this.dots?.length;
                })
                .catch(function(error) {
                    console.log("an error occured " + error);
                })
                .finally(() => {
                    this.loadingDot = false;
                });
        },
        setRowColorClassForReserved(item) {
            let todayDate = this.getCurrentDate("YYYY-mm-dd");
            let orderDate = this.formatDate(
                item.order_created_at.substring(0, 10),
                "yyyy-mm-dd"
            );
            let differenceInDays = this.getDateDifference(orderDate, todayDate);
            // if(differenceInDays && differenceInDays >= 2 && item.appointment_status_id && item.appointment_status_id == 10481003){ // Appointment is exected

            // }
            if (differenceInDays && differenceInDays > 2) {
                return "dueDate";
            }
        },
        updateSortForReserve(sortByForReserve) {
            if (typeof sortByForReserve == "object") {
                this.sortByForReserve = sortByForReserve[0];
            } else {
                this.sortByForReserve =
                    sortByForReserve == undefined ? "id" : sortByForReserve;
            }
        },
        updateSortOrderForReserve(sortDescForReserve) {
            if (typeof sortDescForReserve == "object") {
                !sortDescForReserve[0]
                    ? (this.sortDescForReserve = "ASC")
                    : (this.sortDescForReserve = "DESC");
            } else {
                !sortDescForReserve
                    ? (this.sortDescForReserve = "ASC")
                    : (this.sortDescForReserve = "DESC");
            }
            this.getReservedStocks();
        },
        updatePaginationForReserve(pageForReserve) {
            this.pageForReserve = pageForReserve;
            this.getReservedStocks();
        },
        updateItemsPerPageForReserve(itemsPerPageForReserve) {
            this.itemsPerPageForReserve =
                itemsPerPageForReserve == -1
                    ? this.totalItemsForReserve
                    : itemsPerPageForReserve;
            this.getReservedStocks();
            this.itemsPerPageForReserve =
                this.itemsPerPageForReserve == -1 ? -1 : itemsPerPageForReserve;
        },

        updateSortForSupplierStock(sortByForSupplierStock) {
            if (typeof sortByForSupplierStock == "object") {
                this.sortByForSupplierStock = sortByForSupplierStock[0];
            } else {
                this.sortByForSupplierStock =
                    sortByForSupplierStock == undefined
                        ? "id"
                        : sortByForSupplierStock;
            }
        },
        updateSortOrderForSupplierStock(sortDescForSupplierStock) {
            if (typeof sortDescForSupplierStock == "object") {
                !sortDescForSupplierStock[0]
                    ? (this.sortDescForSupplierStock = "ASC")
                    : (this.sortDescForSupplierStock = "DESC");
            } else {
                !sortDescForSupplierStock
                    ? (this.sortDescForSupplierStock = "ASC")
                    : (this.sortDescForSupplierStock = "DESC");
            }
            this.getSupplierReservedStocks();
        },
        updatePaginationForSupplierStock(pageForSupplierStock) {
            this.pageForSupplierStock = pageForSupplierStock;
            this.getSupplierReservedStocks();
        },
        updateItemsPerPageForSupplierStock(itemsPerPageForSupplierStock) {
            this.itemsPerPageForSupplierStock =
                itemsPerPageForSupplierStock == -1
                    ? this.totalItemsForSupplierStock
                    : itemsPerPageForSupplierStock;
            this.getSupplierReservedStocks();
            this.itemsPerPageForSupplierStock =
                this.itemsPerPageForSupplierStock == -1
                    ? -1
                    : itemsPerPageForSupplierStock;
        },

        updateSortForLatestArticle(sortByForLatestArticle) {
            if (typeof sortByForLatestArticle == "object") {
                this.sortByForLatestArticle = sortByForLatestArticle[0];
            } else {
                this.sortByForLatestArticle =
                    sortByForLatestArticle == undefined
                        ? "id"
                        : sortByForLatestArticle;
            }
        },
        updateSortOrderForLatestArticle(sortDescForLatestArticle) {
            if (typeof sortDescForLatestArticle == "object") {
                !sortDescForLatestArticle[0]
                    ? (this.sortDescForLatestArticle = "ASC")
                    : (this.sortDescForLatestArticle = "DESC");
            } else {
                !sortDescForLatestArticle
                    ? (this.sortDescForLatestArticle = "ASC")
                    : (this.sortDescForLatestArticle = "DESC");
            }
            this.getLatestSalesOfArticle();
        },
        updatePaginationForLatestArticle(pageForLatestArticle) {
            this.pageForLatestArticle = pageForLatestArticle;
            this.getLatestSalesOfArticle();
        },
        updateItemsPerPageForLatestArticle(itemsPerPageForLatestArticle) {
            this.itemsPerPageForLatestArticle =
                itemsPerPageForLatestArticle == -1
                    ? this.totalItemsForLatestArticle
                    : itemsPerPageForLatestArticle;
            this.getLatestSalesOfArticle();
            this.itemsPerPageForLatestArticle =
                this.itemsPerPageForLatestArticle == -1
                    ? -1
                    : itemsPerPageForLatestArticle;
        },

        updateSortForDot(sortByForDot) {
            if (typeof sortByForDot == "object") {
                this.sortByForDot = sortByForDot[0];
            } else {
                this.sortByForDot =
                    sortByForDot == undefined ? "id" : sortByForDot;
            }
        },
        updateSortOrderForDot(sortDescForDot) {
            if (typeof sortDescForDot == "object") {
                !sortDescForDot[0]
                    ? (this.sortDescForDot = "ASC")
                    : (this.sortDescForDot = "DESC");
            } else {
                !sortDescForDot
                    ? (this.sortDescForDot = "ASC")
                    : (this.sortDescForDot = "DESC");
            }
            this.getLatestSalesOfArticle();
        },
        updatePaginationForDot(pageForDot) {
            this.pageForDot = pageForDot;
            this.getLatestSalesOfArticle();
        },
        updateItemsPerPageForDot(itemsPerPageForDot) {
            this.itemsPerPageForDot =
                itemsPerPageForDot == -1
                    ? this.totalItemsForDot
                    : itemsPerPageForDot;
            this.getLatestSalesOfArticle();
            this.itemsPerPageForDot =
                this.itemsPerPageForDot == -1 ? -1 : itemsPerPageForDot;
        },

        updateSortForReceptionHistory(sortByForReceptionHistory) {
            if (typeof sortByForReceptionHistory == "object") {
                this.sortByForReceptionHistory = sortByForReceptionHistory[0];
            } else {
                this.sortByForReceptionHistory =
                    sortByForReceptionHistory == undefined
                        ? "id"
                        : sortByForReceptionHistory;
            }
        },
        updateSortOrderForReceptionHistory(sortDescForReceptionHistory) {
            if (typeof sortDescForReceptionHistory == "object") {
                !sortDescForReceptionHistory[0]
                    ? (this.sortDescForReceptionHistory = "ASC")
                    : (this.sortDescForReceptionHistory = "DESC");
            } else {
                !sortDescForReceptionHistory
                    ? (this.sortDescForReceptionHistory = "ASC")
                    : (this.sortDescForReceptionHistory = "DESC");
            }
            this.getArticleReceptionHistory();
        },
        updatePaginationForReceptionHistory(pageForReceptionHistory) {
            this.pageForReceptionHistory = pageForReceptionHistory;
            this.getArticleReceptionHistory();
        },
        updateItemsPerPageForReceptionHistory(itemsPerPageForReceptionHistory) {
            this.itemsPerPageForReceptionHistory =
                itemsPerPageForReceptionHistory == -1
                    ? this.totalItemsForReceptionHistory
                    : itemsPerPageForReceptionHistory;
            this.getArticleReceptionHistory();
            this.itemsPerPageForReceptionHistory =
                this.itemsPerPageForReceptionHistory == -1
                    ? -1
                    : itemsPerPageForReceptionHistory;
        },
        updateReceptionStatus(item) {
            if (item && item.supplier && item.supplier.supplier_order_id) {
                let data = {
                    is_partial_update: 1,
                    status_id: item.supplier.status_id
                        ? typeof item.supplier.status_id == "object"
                            ? item.supplier.status_id.id
                            : item.supplier.status_id
                        : null,
                    article_id: this.articleId,
                };
                return new Promise((resolve) => {
                    this.$store.state.isLoading = true;
                    axios
                        .put(
                            API_BASE_URL +
                                "/suppliers/order/" +
                                item.supplier.supplier_order_id,
                            data,
                            { headers: this.header }
                        )
                        .then((data) => {
                            this.$toast.success(
                                this.$t(
                                    "receptions.text_messages.reception_updated_successfully"
                                )
                            );
                        })
                        .finally(() => {
                            this.$store.state.isLoading = false;
                            resolve();
                        });
                });
            }
        },
        getSupplierOrderStatuses() {
            return new Promise((resolve, reject) => {
                this.getCodes(
                    "supplier_order_statuses",
                    "receptions.statuses"
                ).then((data) => {
                    this.reception_order_statuses = data;
                    resolve();
                });
            });
        },
        getReceptionStatusById(id) {
            let found = null;
            if (this.reception_order_statuses) {
                this.reception_order_statuses.forEach((element) => {
                    if (element.id == id) {
                        found = element.key;
                    }
                });
            }
            return found;
        },
        async getLatestSalesOfArticle() {
            this.$store.state.isLoading = true;
            await axios
                .get(
                    API_BASE_URL +
                        "/articles/sales/" +
                        this.articleId +
                        (this.pageForLatestArticle
                            ? "?page=" + this.pageForLatestArticle
                            : "") +
                        (this.itemsPerPageForLatestArticle
                            ? "&items_per_page=" +
                              this.itemsPerPageForLatestArticle
                            : "") +
                        ("&sortby=" +
                            this.sortByForLatestArticle +
                            "&sorttype=" +
                            this.sortDescForLatestArticle),
                    this.headers
                )
                .finally(() => {
                    this.$store.state.isLoading = false;
                })
                .then((response) => {
                    this.latestArticles = response.data.data.data;
                    this.totalItemsForLatestArticle = response.data.data.total;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async getReservedStocks() {
            this.$store.state.isLoading = true;
            await axios
                .get(
                    API_BASE_URL + "/orders/stock_reserve/" + this.articleId,
                    this.headers
                )
                .finally(() => (this.$store.state.isLoading = false))
                .then((response) => {
                    this.reservedStocks = response.data.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async getSupplierReservedStocks() {
            this.$store.state.isLoading = true;
            await axios
                .get(
                    API_BASE_URL +
                        "/supplier_order/articles?article_id=" +
                        this.articleId,
                    this.headers
                )
                .finally(() => (this.$store.state.isLoading = false))
                .then((response) => {
                    this.supplierReservedStocks = response.data.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async getFamilyArticle() {
            await axios
                .get(
                    API_BASE_URL + "/family_articles/" + this.familyArticleId,
                    this.headers
                )
                .then((response) => {
                    this.familyArticle = response.data.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async navigateToOrdersPage(orderId) {
            this.$store.commit("seteditId", orderId);
            this.$router.push("/billing/edit");
        },
        async navigateToAppointmentPage(appointmentId) {
            this.$store.commit("setAppointmentRedirectData", {
                id: appointmentId,
            });
            this.$router.push("/scheduler/edit");
        },
        async getArticleReceptionHistory() {
            this.$store.state.isLoading = true;
            await axios
                .get(
                    API_BASE_URL +
                        "/articles/reception_history/" +
                        this.articleId +
                        (this.pageForReceptionHistory
                            ? "?page=" + this.pageForReceptionHistory
                            : "") +
                        (this.itemsPerPageForReceptionHistory
                            ? "&items_per_page=" +
                              this.itemsPerPageForReceptionHistory
                            : "") +
                        ("&sortby=" +
                            this.sortByForReceptionHistory +
                            "&sorttype=" +
                            this.sortDescForReceptionHistory),
                    this.headers
                )
                .finally(() => (this.$store.state.isLoading = false))
                .then((response) => {
                    this.articleReceptionHistory = response.data.data.data;
                    this.totalItemsForReceptionHistory =
                        response.data.data.total;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        switchToEditInput(item) {
            this.editDotQuantityId = item.dot_property;
            item.new_quantity = item.quantity;
            setTimeout(() => {
                document
                    .getElementById("dot_quantity_" + item.dot_property)
                    .select();
            }, 50);
        },
        handleDotUpdate(item) {
            if (this.loadingDot) return;
            // validate dot quantity
            if (item.new_quantity <= 0) {
                this.$toast.error(
                    this.$t("receptions.modify_article.quantity_not_zero")
                );
                return;
            }
            // validate total quantity
            let totalQuantity = this.dots.reduce((acc, dot) => {
                acc += parseInt(dot.quantity);
                return acc;
            }, 0);

            totalQuantity =
                totalQuantity +
                parseInt(item.new_quantity) -
                parseInt(item.quantity);

            if (totalQuantity > this.data.stock) {
                this.$toast.error(
                    this.$t("receptions.modify_article.quantity_than_stock")
                );
                return;
            }
            item.quantity = item.new_quantity;
            this.editDotQuantityId = null;
            this.submitArticleDotModel();
        },
    },
};
</script>
