<template>
    <div :id="'editArticle' + item.srno" style="position:relative; float:right; top:-30%; visibility:hidden">
        <!-- Edit Article -->
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                    v-on="on"
                    v-bind="attrs"
                    @click="
                        editItem(
                            item
                        )
                    "
                    color="primary"
                    class="pl-1"
                    :class="'mdi mdi-pencil'"
                ></v-icon>
            </template>
            {{ $t('icon_group.edit_article') }}
        </v-tooltip>
        <!-- Edit Note -->
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                    v-on="on"
                    v-bind="attrs"
                    @click="
                        addTextAndGroupToArticle(
                            item
                        )
                    "
                    
                    color="warning"
                    class="pl-1"
                    :class="
                        item.text
                            ? 'mdi mdi-note-edit-outline'
                            : 'mdi mdi-note-edit'
                    "
                ></v-icon>
            </template>
            {{ $t('icon_group.edit_note_article') }}
        </v-tooltip>
        <!-- Copy EAN Code -->
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                    v-on="on"
                    v-bind="attrs"
                    @click="
                        copyEan(
                            item
                        )
                    "
                    color="success"
                    class="pl-1"
                    :class="'mdi mdi-content-copy'"
                ></v-icon>
            </template>
            {{ $t('icon_group.copy_ean') }}
        </v-tooltip>
    </div>
</template>

<script>
export default {
    name: "IconGroupForArticle",
    data() {
        return {
            stockUnits: this.value,
            buttonArray: [0, 1, 2, 4],
        };
    },
    props: {
        item: [Object, String]
    },
    methods: {
        addTextAndGroupToArticle(){
            this.$emit("addTextAndGroupToArticle",  this.item);
        },
        editItem(){
            this.$emit("editItem",  this.item);
        },
        copyEan(){
            this.copyText(this.item?.article_original_name?.identifier);
        }
    },
};
</script>

<style scoped>

</style>
