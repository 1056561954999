<template>
    <v-row>
        <v-data-table
            fixed-header
            :footer-props="{
                'items-per-page-options': dataTableOptions,
            }"
            :headers="headers"
            :hide-default-header="true"
            :items="rec_items"
            :server-items-length="totalItems"
            :items-per-page="itemsPerPage"
            :options.sync="options"
            :sort-by="sortBy"
            :sort-desc.sync="sortDesc"
            :expanded.sync="expanded"
            show-expand
            :loading-text="this.$t('loading')"
            @update:page="updatePagination"
            @update:items-per-page="updateItemsPerPage"
            class="elevation-0"
            item-key="id"
            :height="'calc(-212px + 100vh)'"
            style="width: 100%"
            @update:sort-by="updateSort"
            @update:sort-desc="updateSortOrder"
            :key="forceReloadComponentKey"
        >
            <template #header="{ props: { headers } }">
                <thead class="v-data-table-header">
                    <tr>
                        <template v-for="header in headers">
                            <th :style="getHeaderStyle(header)" :key="header.value" scope="col" class="text-start pr-0 column" v-bind:class="[header.sortable ? 'sortable' : '', sortBy == header.value ? 'active': '', sortOrd ? 'ASC':'DESC']" @click="header.sortable ? changeSort(header.value) : ''">
                                <template v-if="header.value == 'actions'">
                                    <v-row justify="space-between">
                                        <v-col
                                            class="d-flex dontbreakline"
                                            cols="12"
                                            sm="2"
                                        >
                                            {{ header.text }}
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-else>
                                    {{ header.text }}
                                </template>
                                <v-icon v-if="header.sortable" class="v-data-table-header__icon" small>
                                    <template v-if="sortOrd">
                                        mdi-arrow-up
                                    </template>
                                    <template v-else>
                                        mdi-arrow-down
                                    </template>
                                </v-icon>
                            </th>
                        </template>
                    </tr>
                </thead>
            </template>
            <!-- Filters -->
            <template v-slot:top>
                <v-toolbar flat class="pt-4">
                    <v-col class="d-flex" cols="12" sm="12">
                        <v-menu
                            v-model="menu_rec_date"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="rec_date"
                                    :label="$t('receptions.rec_date')"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    @click:append="rec_date = null"
                                    append-icon="mdi-close"
                                    class="mr-2 to-upper"
                                    :outlined="is_boxes"
                                    :dense="is_boxes"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                :first-day-of-week="1"
                                :weekday-format="getDay"
                                v-model="rec_date"
                                @input="menu_rec_date = false"
                                no-title
                                scrollable
                                :locale="userLanguageId"
                            ></v-date-picker>
                        </v-menu>

                        <v-menu
                            v-model="menu_ordered_date"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="ordered_date"
                                    :label="$t('receptions.ordered_date')"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    @click:append="ordered_date = null"
                                    append-icon="mdi-close"
                                    class="mr-2 to-upper"
                                    :outlined="is_boxes"
                                    :dense="is_boxes"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                :first-day-of-week="1"
                                :weekday-format="getDay"
                                v-model="ordered_date"
                                @input="menu_ordered_date = false"
                                no-title
                                scrollable
                                :locale="userLanguageId"
                            ></v-date-picker>
                        </v-menu>

                        <v-autocomplete
                            flat
                            :items="suppliers"
                            v-model="supplier"
                            :label="$t('receptions.supplier')"
                            class="mr-2 to-upper"
                            item-value="id"
                            item-text="name"
                            @click:append="supplier = null"
                            append-icon="mdi-close"
                            return-object
                            :outlined="is_boxes"
                            :dense="is_boxes"
                            hide-no-data
                        ></v-autocomplete>

                        <v-autocomplete
                            flat
                            :items="clients"
                            v-model="client"
                            :label="$t('receptions.ordered_for')"
                            class="mr-2 to-upper"
                            item-value="id"
                            :item-text="'clientname'"
                            @click:append="client = null"
                            :search-input.sync="searchClient"
                            append-icon="mdi-close"
                            return-object
                            :outlined="is_boxes"
                            :dense="is_boxes"
                            hide-no-data
                        ></v-autocomplete>

                        <!-- Article -->
                        <v-combobox
                            v-model="manualSelectedItem"
                            class="mr-2 to-upper"
                            :items="articles"
                            :search-input.sync="searchArticles"
                            :item-text="'title_description[0].description'"
                            :item-value="'id'"
                            :label="$t('billings.search_article')"
                            :placeholder="
                                $t('add_automatically_articles.placeholder')
                            "
                            return-object
                            append-icon="mdi-close"
                            v-on:keydown="
                                handleInput($event, 'everything', 150)
                            "
                            v-on:keydown.enter.prevent=""
                            ref="billDropdown"
                            @click:append="
                                manualSelectedItem = null;
                                searchArticles = null;
                            "
                            :outlined="is_boxes"
                            :dense="is_boxes"
                            :hide-no-data="true"
                            no-filter
                        >
                            <template v-slot:append>
                                <v-progress-circular
                                    v-if="isLoadingSearchArticles"
                                    size="18"
                                    width="2"
                                    color="info"
                                    indeterminate
                                ></v-progress-circular>
                                <template v-if="!isLoadingSearchArticles">
                                    <v-icon
                                        @click="
                                            manualSelectedItem = null;
                                            searchArticles = null;
                                        "
                                    >
                                        mdi-close
                                    </v-icon>
                                </template>
                            </template>
                            <template slot="item" slot-scope="{ item }">
                                <v-list-item-content>
                                    <template
                                        v-if="
                                            item &&
                                                item.title_description &&
                                                item.title_description.length >
                                                    0
                                        "
                                    >
                                        {{
                                            item.title_description[0]
                                                .description
                                        }}
                                    </template>
                                </v-list-item-content>
                            </template>
                        </v-combobox>

                        <v-text-field
                            class="mr-3 to-upper"
                            :label="$t('receptions.reference')"
                            v-bind:clearable="true"
                            clear-icon="mdi-close"
                            v-model="search_ref"
                            @focus="reference = null"
                            @input="debounceInput"
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        ></v-text-field>

                        <v-autocomplete
                            v-model="status"
                            :items="statuses"
                            :label="$t('receptions.status')"
                            item-value="id"
                            item-text="text"
                            class="mr-2"
                            return-object
                            multiple
                            :outlined="is_boxes"
                            :dense="is_boxes"
                            @click:append="status = null"
                            append-icon="mdi-close"
                        ></v-autocomplete>
                    </v-col>
                </v-toolbar>
            </template>
            <!-- Data Items -->
            <template v-slot:item="{ item }">
                <tr
                    @click="addToExpand(item)"
                    :class="setRowColorClass(item, index)"
                >
                    <td>
                        <template v-if="item.supplier">
                            {{ item.supplier.identifier }}
                        </template>
                        <template v-else-if="item.supplier_id == 0">
                            INT
                        </template>
                    </td>
                    <td>
                        <template v-if="item.orderer_user_id && item.orderer">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">{{
                                        item.orderer.short_name
                                    }}</span>
                                </template>
                                <span>{{ item.orderer.name }}</span>
                            </v-tooltip>
                        </template>
                    </td>
                    <td>
                        <template
                            v-if="
                                item.supplier_order_details &&
                                    item.supplier_order_details.article
                            "
                        >
                            {{
                                item.supplier_order_details.article
                                    .title_description[0].description
                            }}
                            <br />
                            <i>{{
                                item.supplier_order_details.article.identifier
                            }}</i>
                        </template>
                        <template v-else> </template>
                    </td>
                    <td align="right">
                        <!-- Ordered Quantity -->
                        <span class="text-blue">
                            <template v-if="item.supplier_order_details">
                                {{
                                    item.supplier_order_details.ordered_quantity
                                }}
                            </template>
                            <template v-else>0</template>
                        </span>
                        /
                        <span class="text-orange">
                            <!-- Confirmed Quantity -->
                            <template v-if="item.supplier_order_details">
                                {{
                                    item.supplier_order_details
                                        .confirmed_quantity
                                }}
                            </template>
                            <template v-else>0</template>
                        </span>
                        /
                        <span class="text-green">
                            <!-- Received Quantity -->
                            <template v-if="item.supplier_order_details">
                                {{
                                    item.supplier_order_details
                                        .received_quantity
                                }}
                            </template>
                            <template v-else>0</template>
                        </span>
                    </td>
                    <td>
                        <template v-if="item.order">
                            {{ item.order.client.name }}
                            {{
                                item.order.client.first_name
                                    ? item.order.client.first_name
                                    : ""
                            }}
                        </template>
                        <template v-else>
                            {{ $t("receptions.internal_stock") }}
                        </template>
                    </td>
                    <td>
                        {{ item.reference }}
                    </td>
                    <!-- <td>
                        <template
                            v-if="item.supplier_order_reception.length == 1"
                        >
                            {{
                                item.supplier_order_reception[0]
                                    .delivery_note_reference
                            }}
                        </template>
                    </td>
                    <td>
                        <template v-if="item.supplier_order_reception">
                            {{ item.supplier_order_reception.reception_number }}
                        </template>
                        <template v-else> </template>
                    </td> -->
                    <td>
                        <span v-if="item.supplier_order_details">{{
                            formatDateTime(
                                item.supplier_order_details.delivery_date
                            )
                        }}</span>
                    </td>

                    <td>
                        {{ formatDateTime(item.created_at) }}
                    </td>

                    <td>
                        <template v-if="item.supplier_order_reception">
                            {{ formatDateTime(item.supplier_order_reception.created_at) }}
                        </template>
                        <template v-else> </template>
                    </td>
                    <td>
                        <v-tooltip bottom z-index="9999">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    v-if="item.status.key == 'order_registered'"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    mdi-file-document-multiple
                                </v-icon>
                                <v-icon
                                    v-else-if="
                                        item.status.key ==
                                            'successfully_ordered'
                                    "
                                    v-bind="attrs"
                                    v-on="on"
                                    color="primary"
                                >
                                    mdi-cart-check
                                </v-icon>
                                <v-icon
                                    v-else-if="
                                        item.status.key ==
                                            'order_supplier_error'
                                    "
                                    v-bind="attrs"
                                    v-on="on"
                                    color="red"
                                >
                                    mdi-close-network
                                </v-icon>
                                <v-icon
                                    v-else-if="
                                        item.status.key == 'order_canceled'
                                    "
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    mdi-archivecancel
                                </v-icon>
                                <v-icon
                                    v-else-if="
                                        item.status.key == 'partially_received'
                                    "
                                    v-bind="attrs"
                                    v-on="on"
                                    color="success"
                                >
                                    mdi-archive-arrow-down</v-icon
                                >
                                <v-icon
                                    v-else-if="
                                        item.status.key == 'fully_received'
                                    "
                                    v-bind="attrs"
                                    v-on="on"
                                    color="success"
                                >
                                    mdi-archive-check
                                </v-icon>
                            </template>
                            <span>
                                {{
                                    translateKeys(
                                        item.status.key,
                                        "key",
                                        "receptions.statuses"
                                    )
                                }}
                            </span>
                        </v-tooltip>
                    </td>
                    <td>
                        <v-icon
                            v-if="
                                item.supplier_order_reception.length > 0 &&
                                    checkIfReceived(
                                        item.supplier_order_reception
                                    ) &&
                                    !(expanded[0] && expanded[0].id == item.id)
                            "
                            >mdi-arrow-down-drop-circle-outline</v-icon
                        >
                        <v-icon
                            color="green"
                            v-if="expanded[0] && expanded[0].id == item.id"
                            >mdi-arrow-up-drop-circle</v-icon
                        >
                    </td>
                </tr>
            </template>
            <!-- Expanded Items -->
            <template v-slot:expanded-item="{ item }">
                <tr style="background-color:#EBF4E4">
                    <td colspan="3">
                        <strong>
                            {{
                                $t(
                                    "receptions.subtable.delivery_note_reference"
                                )
                            }}
                        </strong>
                    </td>
                    <td colspan="3">
                        <strong>
                            {{ $t("receptions.subtable.reception_no") }}
                        </strong>
                    </td>
                    <td colspan="2">
                        <strong>
                            {{ $t("receptions.subtable.received_quant") }}
                        </strong>
                    </td>
                    <td colspan="2">
                        <strong>
                            {{ $t("receptions.subtable.received_at") }}
                        </strong>
                    </td>
                    <td></td>
                </tr>
                <template
                    v-for="(reception, index) in item.supplier_order_reception"
                >
                    <tr
                        v-if="reception.received_quantity != 0"
                        style="background-color:#f7fff1"
                        :key="index"
                    >
                        <td align="left" colspan="3">
                            {{ reception.delivery_note_reference }}
                        </td>
                        <td align="left" colspan="3">
                            {{ reception.reception_number }}
                        </td>
                        <td align="left" colspan="2">
                            {{ reception.received_quantity }}
                        </td>
                        <td align="left" colspan="2">
                            {{ formatDateTime(reception.created_at) }}
                        </td>
                        <td></td>
                    </tr>
                </template>
            </template>
        </v-data-table>
        <ModifyReceptionDetails
            v-if="showPopUp"
            @change-popup-model-visibility="changePopUpModelVisibility"
            :rec_statuses="original_statuses"
            :data="editData"
        ></ModifyReceptionDetails>

        <NewExternalOrder
            v-if="showNewExternalOrderPopUp"
            @change-popup-model-visibility="changeExternalPopUpModelVisibility"
            :rec_statuses="original_statuses"
        ></NewExternalOrder>

        <v-btn
            @click="showExternalOrderPopUpToChangeStatus()"
            class="d-none"
            id="create_external_order"
        ></v-btn>
    </v-row>
</template>

<script>
import axios from "@/plugins/axios";
import _ from "lodash";
import { API_BASE_URL } from "@/config";
import { RECEPTION_LISTING_MODE } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import ModifyReceptionDetails from "@/components/Reception/ModifyReceptionDetails.vue";
import NewExternalOrder from '@/components/Reception/NewExternalOrder.vue';

export default {
    name: "ReceptionListing",
    components: {
        ModifyReceptionDetails,
        NewExternalOrder
    },
    mounted() {      
        this.header = { Authorization: "Bearer " + this.token };
        this.$store.commit("setFormMode", RECEPTION_LISTING_MODE);
        this.getStatuses().then(() => {
        });
        this.setDefaultFamilyArticle();
        this.getSuppliers();
    },
    data() {
        return {
            is_boxes: this.getCompanyPropertyFromLocalStorage(
                "boxes_on_fields",
                1
            ),
            dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
            lastPage: null,
            totalItems: null,
            itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
            sortBy: "created_at",
            sortOrd: false,
            sortDesc: true,
            options: {},
            pagination: {},
            token: this.$store.state.AccessToken,
            header: {
                Authorization: "Bearer " + this.$store.state.AccessToken,
            },
            status: null,
            statuses: [],
            suppliers: [],
            supplier: null,
            searchSupplier: null,
            menu_rec_date: false,
            menu_ordered_date: false,
            clients: [],
            client: null,
            rec_date: null,
            ordered_date: null,
            searchClient: null,
            search_ref: null,
            rec_items: [],
            isLoading: false,
            expanded: [],
            isDebounceCancelled: false,
            showPopUp: false,
            showNewExternalOrderPopUp: false,
            original_statuses: [],
            editData: null,
            isLoadingSearchArticles: false,
            searchArticles: null,
            articles: [],
            familyArticle: null,
            manualSelectedItem: null,
        };
    },
    methods: {
        addExternalOrder(){
            console.log("Adding external order");
        },
        fetchArticlesSuggesListDebounced() {
            clearTimeout(this._searchTimerId);
            this._searchTimerId = setTimeout(() => {
                this.fetchArticlesSuggesList();
            }, 500); /* 500ms throttle */
        },
        fetchArticlesSuggesList(bypassConditions = false) {
            if (
                this.manualSelectedItem &&
                typeof this.manualSelectedItem == "object" &&
                this.manualSelectedItem?.title_description &&
                this.searchArticles ==
                    this.manualSelectedItem?.title_description[0].description
            ) {
                return;
            }

            if (
                !this.searchArticles ||
                typeof this.searchArticles == "object"
            ) {
                return;
            }
            if (!this.familyArticle) {
                return;
            }

            this.isLoadingSearchArticles = true;
            this.articles = [];
            const { sortBy, sortDesc, itemsPerPage, page } = this.options;
            axios
                .get(
                    API_BASE_URL +
                        "/billings/articles/search" +
                        "?page=1" +
                        // '&items_per_page='                          + ((itemsPerPage == -1 ? this.itemsPerPage : itemsPerPage )|| this.itemsPerPage) +
                        "&order_by=" +
                        (sortBy ? sortBy[0] : this.sortBy) +
                        "&order_direction=" +
                        (sortDesc
                            ? !!sortDesc[0] || this.sortDesc
                                ? "desc"
                                : "asc"
                            : "asc") +
                        "&parent_also=true" +
                        (this.familyArticle
                            ? "&family=" + this.familyArticle.id
                            : "") +
                        (this.searchArticles
                            ? "&identifier=" +
                              encodeURIComponent(this.searchArticles)
                            : ""),
                    { headers: this.header }
                )
                .then(({ data }) => {
                    this.articles = data.data.map((element) => {
                        if (element.property_01) {
                            element.property_01 = this.$t(element.property_01);
                        }
                        if (element.property_02) {
                            element.property_02 = this.$t(element.property_02);
                        }
                        return element;
                    });
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.isLoadingSearchArticles = false;
                });
        },
        setRowColorClass(item, index) {
            let className = "";
            if (item.status && item.status.key == "payed") {
                className = "greenColor";
            }

            if (
                item.status &&
                (item.status.key === "order_canceled" ||
                    item.status.key === "order_supplier_error")
            ) {
                className = "redColorBackground";
            }

            if (item.status && item.status.key === "fully_received") {
                className = "greenColorBackground";
            }

            if (item.status && item.status.key === "partially_received") {
                className = "yellowColorBackground";
            }

            return className;
        },
        debounceInput: _.debounce(function(e) {
            if (this.isDebounceCancelled) {
                this.isDebounceCancelled = false;
                return false;
            }
            console.log("this input");
        }, 500),
        changeDialog() {
            this.dialog = false;
        },
        checkIfReceived(reception) {
            let received_quantity = 0;
            if (reception.length > 0) {
                reception.forEach((element) => {
                    received_quantity += element.received_quantity;
                });
            }
            return received_quantity;
        },
        fetchData(firstTimeSearch = false, currentPage = null) {
            this.resetDataTable(currentPage);
            let statusIds = this.status ? this.status.map((a) => a.id) : [];
            let allStatus = "";
            if (statusIds && statusIds.length > 0) {
                statusIds.forEach((element) => {
                    allStatus += element.join(",") + ",";
                });
                if (allStatus) {
                    allStatus = allStatus.replace(/,\s*$/, "");
                }
            }
            console.log("status ids", allStatus);
            axios
                .get(
                    API_BASE_URL +
                        "/receptions/orders?page=" +
                        this.page +
                        "&items_per_page=" +
                        this.itemsPerPage +
                        "&sortBy=" +
                        this.sortBy +
                        (this.sortOrd ? "&sortOrd=ASC" : "&sortOrd=DESC") +
                        (this.supplier
                            ? "&supplier_id=" + this.supplier.id
                            : "") +
                        (this.search_ref
                            ? "&reference=" + this.search_ref
                            : "") +
                        (allStatus ? "&status_id=" + allStatus : "") +
                        (this.rec_date
                            ? "&reception_date=" + this.rec_date
                            : "") +
                        (this.ordered_date
                            ? "&ordered_date=" + this.ordered_date
                            : "") +
                        (this.manualSelectedItem && this.manualSelectedItem.id
                            ? "&article_id=" + this.manualSelectedItem.id
                            : "") +
                        (this.client ? "&ordered_for=" + this.client.id : ""),
                    { headers: this.header }
                )
                .then(({ data }) => {
                    this.rec_items = data.data;
                    this.lastPage = data.last_page;
                    this.page = data.current_page;
                    this.totalItems = data.total;
                    this.itemsPerPage = parseInt(data.per_page);
                    this.retainJustSupplierWithOrders();
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {});
        },
        retainJustSupplierWithOrders() {
            let newSuppliers = [];
            this.rec_items.forEach((rec_item) => {
                if (rec_item.supplier) {
                    newSuppliers.push(rec_item.supplier);
                }
            });
            // this.suppliers = newSuppliers;
        },
        addToExpand(item) {
            console.log(
                "check if received",
                item.supplier_order_details,
                this.checkIfReceived(item.supplier_order_reception)
            );
            // Show Expanded View if already received
            if (
                item.supplier_order_reception.length > 0 &&
                this.checkIfReceived(item.supplier_order_reception)
            ) {
                let processedQuantity = 0;
                item.supplier_order_reception.forEach((reception) => {
                    if (!(reception.modified && reception.modified == true)) {
                        // reception.received_quantity =
                        //     reception.received_quantity - processedQuantity;
                        processedQuantity += reception.received_quantity;
                        reception.modified = true;
                    }
                });
                if (this.expanded && this.expanded.length > 0) {
                    if (this.expanded[0].id == item.id) {
                        this.expanded = [];
                    } else {
                        this.expanded = [];
                        this.expanded.push(item);
                    }
                } else {
                    this.expanded.push(item);
                }
            }

            if (
                item.supplier_order_details &&
                ((item.supplier_order_details.confirmed_quantity == 0 &&
                    item.supplier_order_details.received_quantity == 0) ||
                    item.supplier_order_details.received_quantity <
                        item.supplier_order_details.confirmed_quantity)
            ) {
                // if not received then show pop up model to change its status and for more details
                this.editData = JSON.parse(JSON.stringify(item));

                this.showPopUpToChangeStatus();
            }
        },
        showPopUpToChangeStatus() {
            this.showPopUp = true;
        },
        hidePopUpToChangeStatus() {
            this.hidePopUp = false;
        },
        showExternalOrderPopUpToChangeStatus() {
            this.showNewExternalOrderPopUp = true;
        },
        hideExternalOrderPopUpToChangeStatus() {
            this.hideExternalOrderPopUp = false;
        },
        showImage(item) {
            alert("THE IMAGE");
        },
        deleteItem(item) {},
        updatePagination(page) {
            this.page = page;
            this.fetchData(false, page);
        },
        updateItemsPerPage(itemsPerPage) {
            this.itemsPerPage =
                itemsPerPage == -1 ? this.totalItems : itemsPerPage;
            this.fetchData();
            this.itemsPerPage = this.itemsPerPage == -1 ? -1 : itemsPerPage;
        },
        getStatuses() {
            return new Promise((resolve, reject) => {
                this.getCodes(
                    "supplier_order_statuses",
                    "receptions.statuses"
                ).then((data) => {
                    this.statuses = data;
                    this.original_statuses = data;
                    this.modifyStatusesList();
                    resolve();
                });
            });
        },
        modifyStatusesList() {
            let modifiedStatus = [[], [], [], []];
            let statuses = [[], [], [], []];
            console.log("Status");
            this.statuses.forEach((status) => {
                if (
                    status.key == "order_registered" ||
                    status.key == "order_canceled"
                ) {
                    modifiedStatus[0].push(status.id);
                    statuses[0] = {
                        text: this.$t("receptions.statuses.ordered"),
                        id: modifiedStatus[0],
                    };
                } else if (status.key == "fully_received") {
                    modifiedStatus[1].push(status.id);
                    statuses[1] = {
                        text: this.$t("receptions.statuses.fully_received"),
                        id: modifiedStatus[1],
                    };
                } else if (
                    status.key == "successfully_ordered" ||
                    status.key == "partially_received"
                ) {
                    modifiedStatus[2].push(status.id);
                    statuses[2] = {
                        text: this.$t(
                            "receptions.statuses.successfully_ordered"
                        ),
                        id: modifiedStatus[2],
                    };
                }
                else if(status.key == "order_supplier_error") {
                    modifiedStatus[3].push(status.id);
                    statuses[3] = {
                        text: this.$t(
                            "receptions.statuses.order_supplier_error"
                        ),
                        id: modifiedStatus[3],
                    };
                }
            });

            this.statuses = statuses;
            this.status = [statuses[0], statuses[2], statuses[3]];
        },
        changeSort(column) {
            if (this.sortBy === column) {
                this.sortOrd = !this.sortOrd;
            } else {
                this.sortBy = column;
                this.sortOrd = false;
            }
            this.fetchData();
        },
        updateSort(sortBy) {
            if (typeof sortBy == "object") {
                this.sortBy = sortBy[0];
            } else {
                this.sortBy = sortBy;
            }
            this.fetchData();
        },
        updateSortOrder(sortDesc) {
            if (typeof sortDesc == "object") {
                !sortDesc[0] ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
            } else {
                !sortDesc ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
            }

            this.fetchData();
        },
        changePopUpModelVisibility(isReceptionSaved) {
            this.editData = null;
            this.showPopUp = !this.showPopUp;
            console.log("saved_reception", isReceptionSaved);
            if (isReceptionSaved) {
                this.rec_items = [];
                this.fetchData();
            }
        },
        changeExternalPopUpModelVisibility(isReceptionSaved){
            this.showNewExternalOrderPopUp = !this.showNewExternalOrderPopUp;
            if (isReceptionSaved) {
                this.rec_items = [];
                this.fetchData();
            }
        },
        setDefaultFamilyArticle() {
            let defaultFamilyArticle = null;
            this.familyArticles.forEach((element) => {
                if (element.is_default) {
                    defaultFamilyArticle = element;
                }
            });
            if (!defaultFamilyArticle) {
                this.familyArticle = this.familyArticles[0];
            } else {
                this.familyArticle = defaultFamilyArticle;
            }
            // Fetch suggested List
            if (this.familyArticle && this.familyArticle.show_on_load) {
                this.fetchArticlesSuggesList(true);
            }
        },

        getSuppliers() {
            this.suppliers = [];
            axios
                .get(
                    API_BASE_URL +
                        "/suppliers?internal_supplier=true&items_per_page=-1&parent_also=true" +
                        (this.searchSupplier
                            ? "&search=" + this.searchSupplier
                            : ""),
                    { headers: this.header }
                )
                .then(({ data }) => {
                    this.suppliers = data.data;
                })
                .catch(function(error) {});
        },
    },
    computed: {
        headers() {
            return [
                // {
                //     text: this.$t("receptions.id"),
                //     align: "start",
                //     sortable: true,
                //     value: "id",
                //     width: "70",
                // },
                {
                    text: this.$t("receptions.supplier"),
                    align: "start",
                    sortable: false,
                    value: "id",
                    width: "3%",
                },
                {
                    text: this.$t("receptions.orderer"),
                    align: "start",
                    sortable: false,
                    value: "id",
                    width: "5%",
                },
                {
                    text: this.$t("receptions.article"),
                    align: "start",
                    sortable: false,
                    value: "id",
                    width: "30%",
                },
                {
                    text: this.$t("receptions.ordered_conf_received_quant"),
                    align: "start",
                    sortable: false,
                    value: "id",
                    width: "5%",
                },
                // {
                //     text: this.$t("receptions.confirmed_quant"),
                //     align: "end",
                //     sortable: false,
                //     value: "id",
                //     width: "5",
                // },
                // {
                //     text: this.$t("receptions.received_quant"),
                //     align: "end",
                //     sortable: false,
                //     value: "id",
                //     width: "5",
                // },
                {
                    text: this.$t("receptions.ordered_for"),
                    align: "start",
                    sortable: false,
                    value: "id",
                    width: "10%",
                },
                {
                    text: this.$t("receptions.reference"),
                    align: "start",
                    sortable: false,
                    value: "id",
                    width: "10%",
                },
                // {
                //     text: this.$t("receptions.delivery_noteref"),
                //     align: "start",
                //     sortable: false,
                //     value: "id",
                //     width: "50",
                // },
                // {
                //     text: this.$t("receptions.reception_no"),
                //     align: "start",
                //     sortable: false,
                //     value: "id",
                //     width: "50",
                // },
                {
                    text: this.$t("receptions.del_date"),
                    align: "start",
                    sortable: false,
                    value: "supplier_order_details.delivery_date",
                    width: "10%",
                },
                {
                    text: this.$t("receptions.ordered_date"),
                    align: "start",
                    sortable: true,
                    value: "created_at",
                    width: "10%",
                },
                {
                    text: this.$t("receptions.received_at"),
                    align: "start",
                    sortable: false,
                    value: "id",
                    width: "5%",
                },
                {
                    text: this.$t("receptions.status"),
                    align: "start",
                    sortable: false,
                    value: "id",
                    width: "2%",
                },
                { text: "", value: "data-table-expand", width: "2%" },
            ];
        },
    },
    watch: {
        familyArticles: {
            handler: function() {
                this.setDefaultFamilyArticle();
                this.$nextTick(() => {
                    // this.fetchArticlesSuggesList(true)
                });
            },
        },
        searchArticles(val) {
            this.fetchArticlesSuggesListDebounced();
        },
        searchClient: _.debounce(function(e) {
            if (
                this.searchClient &&
                this.searchClient.length > 0 &&
                (!this.client ||
                    (this.client &&
                        this.client.name.trim() != this.searchClient.trim()))
            ) {
                this.clients = [];
                axios
                    .get(
                        API_BASE_URL + "/clients?search=" + this.searchClient,
                        {
                            headers: this.header,
                        }
                    )
                    .then(({ data }) => {
                        data.data.forEach((element) => {
                            element.clientname =
                                element.name + " " + (element.first_name ?? "");
                        });
                        this.clients = data.data;
                    })
                    .catch(function(error) {})
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        }, 1000),
        search_ref: _.debounce(function(e) {
            this.fetchData();
        }, 1000),
        rec_date: {
            handler: function() {
                this.fetchData();
            },
        },
        ordered_date: {
            handler: function() {
                this.fetchData();
            },
        },
        manualSelectedItem: {
            handler: function() {
                this.fetchData();
            },
        },
        supplier: {
            handler: function() {
                this.fetchData();
            },
        },
        client: {
            handler: function() {
                this.fetchData();
            },
        },
        status: {
            handler: function() {
                this.fetchData();
            },
        },
    },
};
</script>

<style scoped></style>
