<template>
    <v-row>
        <v-combobox
            :disabled="disabled"
            :autocomplete="autocompleteValueLocal"
            ref="clientName"
            v-model="client"
            :items="clients"
            item-value="id"
            item-text="name"
            required
            class="to-upper clickable_label"
            append-icon="mdi-close"
            :prepend-icon="showPopUpToAdd ? 'mdi-open-in-new' : (client && typeof client == 'object' ? 'mdi-pencil' : '')"
            v-on:keydown="handleInput($event, 'everything', 50)"
            :search-input.sync="search_client"
            no-filter
            v-on:keydown.enter.prevent=""
            @click:prepend="editClient"
            @click:append="removeClientMember"
            return-object
            :outlined="is_boxes"
            :dense="is_boxes"
            single-line
            >

            <template #label>
                {{ type == 'client' ? $t('clients.owner') : $t('vehicles.driver') }}
                <ClientReclamation v-if="checkIfReclamationNeedToShow(client)" :note.sync="client.note"></ClientReclamation>
            </template>
            <template slot="selection" slot-scope="{ item }">
                <span class="text-no-wrap">
                    {{ item.name }} {{ item.first_name ? item.first_name : "" }}
                </span>
            </template>
            <template slot="item" slot-scope="{ item }">
                <v-list-item-content>
                    <p class="client_name">
                        {{ item.name }} {{ item.first_name }}
                    </p>
                    <p class="client_detail" v-if="item.representative_company_id">
                        {{ $t('company') }}
                    </p>
                    <p class="client_detail" v-else>
                        {{ $t('private') }}
                    </p>
                </v-list-item-content>
            </template>
        </v-combobox>
        <v-btn
            v-if="! showPopUpToAdd"
            class="ma-2 px-4 text-white-color"
            color="primary"
            @click="addNewClient"
            >
            {{ $t("vehicles.add") }}
            <v-icon> mdi-plus </v-icon>
        </v-btn>
        <add-client-driver
            v-if="addNewClientDialog"
            :showDialog.sync="addNewClientDialog"
            :create.sync="addClientOperation"
            :membersEnabled.sync="membersEnabled"
            :defaultClient.sync="defaultClient"
            :defaultMember.sync="member"
            @savedClient="saveClient"
            @savedMember="saveMember"
            @buttonClicked="addNewClientDialogClose"
            :type="type"
        />
    </v-row>
</template>
<script>
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import { ADD_MODE } from "@/config";
import { DETAILS_MODE } from "@/config";
import { FAMILY_ARTICLE_CARS } from "@/config";
import VehiclesPageTab from "@/components/Vehicles/VehiclesPageTab";
import AddClientDriver from "@/components/Vehicles/AddClientDriver";
import ConfirmationModel from "@/components/ConfirmationModel";
import AttachmentDialog from "@/components/AttachmentDialog";
import { mdiCheck } from "@mdi/js";
import ClientReclamation from '@/components/Reclamation.vue';

export default{
    components:{
        "add-client-driver": AddClientDriver,
        ClientReclamation
    },
    name:"ClientDriverInputBox",
    props:{
        data: [String, Object, Number, Array],
        type: {type: String, default: 'client'},
        showMembers: {type: Boolean, default: false},
        membersEnabled: {type: Boolean, default: false},
        showPopUpToAdd: {type: Boolean, default: false},
        member: [String, Object, Number, Array],
        disabled: [Boolean, String]
    },
    activated(){
        this.client = null;
        this.member = null;
    },
    data(){
        return {
            is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
            clients: [],
            client: null,
            search_client: null,
            token: this.$store.state.AccessToken,
            header: { Authorization: "Bearer " + this.$store.state.AccessToken },
            autocompleteValueLocal: "off",
            addNewClientDialog: false,
            savedMemberaddClientOperation: null,
            defaultClient: null,
            defaultMember: null,
        };
    },
    methods:{
        removeClientMember(){
            this.client = null; 
            this.member = null;
            this.saveClient(null);
            this.saveMember(null);
        },
        saveClient(data){
            this.client = data;
            this.$emit('update:data', data)
        },
        saveMember(data){
            this.$emit('update:member', data)
        },
        addNewClientDialogClose(data) {
            if (data) {
                // Client Added in last popup
                if (this.addClientOperation == "client") {
                    this.clients = [data];
                    this.client = data;
                }
            } else {
                // reset the values if its just search text because the form just cancelled
                if (this.addClientOperation == "client") {
                    this.client = typeof this.client == "object" ? this.client : null;
                    this.client = typeof this.client == "object" ? this.client : null;
                }
            }
            this.addNewClientDialog = false;
            this.addClientOperation = null;
        },
        editClient(){
            this.addClientOperation = this.type;
            this.addNewClientDialog = true;
            this.defaultClient = this.client ? (typeof this.client == 'object' ? this.client : this.client.name) : this.search_client;
            // this.defaultMember = this.clie
        },
        addNewClient(){
            this.addClientOperation = this.type;
            this.addNewClientDialog = true;
            this.defaultClient = this.search_client;
            // this.defaultMember = this.clie
        },
        fetchClientsListDebounced(){
            if (!this.search_client || typeof this.search_client == "object") {
                return;
            }
            
            axios
                .get( API_BASE_URL + "/clients/search?" + (this.search_client ? "&search=" + encodeURIComponent(this.search_client) : ""),
                    { headers: this.header }
                )
                .then(({ data }) => {
                    this.clients = data.data;
                })
                .catch((err) => {})
                .finally(() => {});
        },
    },
    watch:{
        client:{
            handler: function(val){
                this.$emit('update:data', val);
            },
            immediate:true,
            deep: true
        },
        member(val){
            this.$emit('update:member', val);
        },
        search_client(val) {
            if (!val || this.client_id == val) {
                return;
            }
            clearTimeout(this._searchTimerId);
            this._searchTimerId = setTimeout(() => {
                this.fetchClientsListDebounced();
            }, 500);
        },
        data(val){
            if(val && typeof val == 'object'){
                this.client = val;
            }
            else{
                this.client = null;
                this.search_client = null;
                this.defaultClient = null;
            }
        }
    },
    mounted(){
        
    }
}
</script>