var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{staticClass:"mr-3 to-upper",attrs:{"autocomplete":"off","label":_vm.$t('vehicles.search'),"autofocus":"","prepend-icon":"mdi-magnify","clearable":true,"clear-icon":"mdi-close"},on:{"input":_vm.searchHandler},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{key:_vm.$store.state.forceReloadComponentKey,attrs:{"headers":_vm.headers,"items":_vm.formattedItems,"options":{
          itemsPerPage: _vm.perPage,
          itemsPerPageOptions: _vm.perPageOptions,
          page: _vm.page,

      },"height":_vm.$store.state.defaultTableHeight,"fixed-header":"","footer-props":{
          'items-per-page-options': _vm.perPageOptions,
        },"server-items-length":_vm.totalItems},on:{"update:page":function (newPage) {
      _vm.page = newPage
      _vm.getAddressBooks()
    },"update:items-per-page":function (itemsPerPage) {
      _vm.perPage = itemsPerPage
      _vm.getAddressBooks()
    },"click:row":_vm.openFormDialog}}),_c('span',{attrs:{"id":"address-book-form"},on:{"click":function($event){return _vm.openFormDialog(null)}}},[_c('AddressBookForm',{attrs:{"address-book":_vm.selectedAddressBook},on:{"update:addressBook":function($event){_vm.selectedAddressBook=$event},"update:address-book":function($event){_vm.selectedAddressBook=$event},"updated":function (data) { return _vm.hydrateData(data); },"deleted":function (data) {
          _vm.addressBooks = _vm.addressBooks.filter(function (ab) { return ab.id !== data.id; })
          _vm.totalItems--;
          _vm.formatItems()
        }},model:{value:(_vm.formDialog),callback:function ($$v) {_vm.formDialog=$$v},expression:"formDialog"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }