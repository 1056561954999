<template>
  <v-dialog v-model="note_dialog" persistent max-width="800px">
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ label || $t("scheduler.add_bill_note") }}</span>
      </v-card-title>
      <v-card-text>
        <NoteEditor
          :label="$t('scheduler.note')"
          v-model="note"
          :showLabel="false"
        />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pr-2">
    <v-spacer></v-spacer>
        <v-btn color="grey-lighten-2" @click="close()">
          {{ $t("receptions.modify_article.cancel") }}
          <v-icon dark right> mdi-keyboard-return </v-icon>
        </v-btn>
        <v-btn color="green" dark @click="save()">
          {{ $t("receptions.modify_article.save") }}
          <v-icon dark right> mdi-content-save </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import NoteEditor from "./NoteEditor.vue";
export default {
  name: "NoteEditorModal",
  emits: ["input", "close", "save"],
  components: {
    NoteEditor,
  },
  props: {
    label: {
      default: null,
    },
    value: {
      required: true,
    },
    dialog: [Boolean]
  },

  computed: {
    note: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {
      note_dialog: this.dialog
    };
  },
  watch: {
    dialog(val) {
      this.note_dialog = val;
    }
  },
  methods: {
    close() {
      this.$emit("update:dialog", false);
      this.$emit("close");
    },
    save() {
      this.$emit("update:dialog", false);
      this.$emit("save", this.note);
    },
  },
};
</script>
