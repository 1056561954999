<template>
  <v-dialog max-width="800" :value="value" v-if="isset(order)">
    <v-card>
      <v-card-title> {{ $t('order_history_for') }} {{ order.bill_number }}</v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <td>{{ $t('order_history_title') }}</td>
              <td>{{ $t('order_history_identifier') }}</td>
              <td>{{ $t('order_history_date') }}</td>
            </tr>
            </thead>
            <tbody>
            <tr v-if="parentOrder" @click="editItem(parentOrder)">
              <td>{{ $t('converted_from') }} {{ $t('billings.types.' + parentOrder.type.key) }}</td>
              <td>{{ parentOrder.bill_number }}</td>
              <td>{{ formatDate(parentOrder.created_at) }}</td>
            </tr>
            <tr v-if="childOrder" @click="editItem(childOrder)">
              <td>{{ $t('converted_to') }} {{ $t('billings.types.' + childOrder.type.key) }}</td>
              <td>{{ childOrder.bill_number }}</td>
              <td>{{ formatDate(childOrder.created_at) }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <span @click="close()">
      <CloseButton/>
          </span>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import {API_BASE_URL} from '@/config';
import CloseButton from "@/components/Buttons/CloseButton.vue";

export default {
  name: 'OrderHistory',
  components: {CloseButton},
  emits: ['input', 'updateBillData'],
  props: {
    order: {
      required: true
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      parentOrder: null,
      childOrder: null,
      axiosHeaders: {
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
      }
    }
  },

  watch: {
    async value(val) {
      if (!val || !this.order) {
        this.childOrder = null;
        this.parentOrder = null;
      }

      if (this.isset(this.order.parent_order_id)) {
        this.parentOrder = await this.getOrder(this.order.parent_order_id);
      } else {
        this.parentOrder = null

      }

      if (this.isset(this.order.child_order_id)) {
        this.childOrder = await this.getOrder(this.order.child_order_id);
      } else {
        this.childOrder = null
      }

    }
  },

  methods: {
    close() {
      this.$emit('input', false);
    },
    getOrder(orderId) {
      return new Promise((resolve, reject) => {
        return axios.get(API_BASE_URL + '/orders/' + orderId, {headers: this.axiosHeaders})
            .then(response => {
              resolve(response.data.data)
            })
      })
    },
    editItem(item) {
      this.$emit('updateBillData', item.id)
      this.close()
    },

  }
}
</script>
